import "@/assets/scss/styles.scss";

import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import plugins from "./plugins";
import store from "./store";
import router from "./router";
import globalComponents from "./components";

createApp(App)
  .use(store)
  .use(router)
  .use(plugins)
  .use(globalComponents)
  .mount("#app");
