import { WNInterface } from "vue-wni";
import { App } from "vue";
import languagePack from "@/assets/json/language.json";
import config from "@/config";

enum LugStayLanguages {
  "KOREAN" = "ko",
  "ENGLISH" = "en",
  "SIMPLIFIED_CHINESE" = "cn",
  "TRADITIONAL_CHINESE" = "hk",
  "JAPANESE" = "jp",
  "VIETNAMESE" = "vn",
  "SYSTEM" = "system",
}

const GlobalLocales = {
  ko: "ko",
  en: "en",
  cn: "zh-CN",
  hk: "zh-HK",
  jp: "ja",
  vn: "vi",
} as const;

class Language {
  private static instance;

  private static readonly LANGUAGE_KEY: string = "__UHOO_LANGUAGE_KEY__";

  public currentLanguage: LugStayLanguages;

  private systemLanguage: any;

  private languagePack: any;

  private constructor(languagePack: any) {
    this.currentLanguage = this.getDefaultLanguage();
    this.languagePack = languagePack;
    this.getSystemLanguage().then((lang) => {
      this.systemLanguage = lang;
    });
  }

  static getInstance(languagePack: any): Language {
    if (!Language.instance) {
      Language.instance = new Language(languagePack);
    }
    return Language.instance;
  }

  getDefaultLanguage(): LugStayLanguages {
    const storedLanguage = localStorage.getItem(
      Language.LANGUAGE_KEY,
    ) as LugStayLanguages;

    return (
      storedLanguage ||
      (config.userLocale.indexOf(LugStayLanguages.KOREAN) > -1
        ? LugStayLanguages.KOREAN
        : LugStayLanguages.ENGLISH)
    );
  }

  parseLanguage(language: string): Promise<LugStayLanguages> | any {
    const targetIndex = Object.values(GlobalLocales).findIndex(
      (locale: string) => language.indexOf(locale.substring(0, 2)) > -1,
    );

    if (targetIndex < 0) {
      return language;
    }

    return Object.keys(GlobalLocales)[targetIndex];
  }

  translate(string: string) {
    let langKey: LugStayLanguages = this.currentLanguage;

    if (langKey === "system") {
      langKey = this.systemLanguage;
    }

    if (langKey === "ko") {
      // eslint-disable-next-line no-nested-ternary
      return this.languagePack[string]
        ? this.languagePack[string][langKey]
          ? this.languagePack[string][langKey]
          : string
        : string;
    }

    let language = "";

    if (this.languagePack[string]?.[langKey]) {
      language = this.languagePack[string][langKey];
    } else if (this.languagePack[string].en) {
      language = this.languagePack[string].en;
    } else {
      language = string;
    }
    return language;
  }

  getGoogleMapLocale(language: keyof typeof GlobalLocales) {
    return GlobalLocales[language] || LugStayLanguages.KOREAN;
  }

  getSystemLanguage(): Promise<LugStayLanguages> {
    return new Promise((resolve, reject) => {
      if (WNInterface.isNative) {
        WNInterface.execute("wnAppInfo", {
          callback: WNInterface.cb((e: any) => {
            const { deviceLocale } = e;
            resolve(this.parseLanguage(deviceLocale));
          }),
        });
      } else {
        const browserLanguage =
          window.navigator.language || LugStayLanguages.KOREAN;
        resolve(this.parseLanguage(browserLanguage));
      }
    });
  }
}

const instance = Language.getInstance(languagePack);

export const LanguagePlugin = {
  install: (app: App): void => {
    app.provide("$__t", instance.translate.bind(instance));
    app.config.globalProperties.$__t = instance.translate.bind(instance);
  },
};

export default instance;
