import { Module, GetterTree, MutationTree, ActionTree, Mutation } from "vuex";
import { State } from "../typings";
import network from "@/plugins/network";
import config from "@/config";

declare global {
  interface Window {
    Kakao: any;
    AppleID: any;
    gapi: any;
  }
}

export class AuthState {
  public profile = {};

  public accessToken = network.accessToken;
}

const getters: GetterTree<AuthState, State> = {
  profile: (state, getters) => state.profile,
  accessToken: (state, getters) => state.accessToken,
};

const mutations: MutationTree<AuthState> = {
  SET_AUTH(state, { profile, accessToken }) {
    state.profile = profile;

    if (accessToken) {
      network.setAccessToken(accessToken);
      state.accessToken = accessToken;
    }
  },
  UNSET_AUTH(state) {
    state.profile = {};
    network.unsetAccessToken();
  },
};

const actions: ActionTree<AuthState, State> = {
  async verify({ state, rootState, commit, dispatch }, payload) {
    try {
      const data = await network.get("/v1/passport/oauth/user/me");

      commit("SET_AUTH", { profile: data });
      return data;
    } catch (e) {
      commit("UNSET_AUTH");
      return null;
    }
  },

  async requestLoginCode({ state, rootState, commit, dispatch }, payload) {
    const { appKey } = config.get("uhoo");
    const data = await network.post("/v1/passport/oauth/phone/request", {
      ...payload,
      app_key: appKey,
    });

    return data;
  },

  async requestRegisterCode({ state, rootState, commit, dispatch }, payload) {
    const { appKey } = config.get("uhoo");
    const data = await network.post("/v1/passport/oauth/phone/register", {
      ...payload,
      app_key: appKey,
    });

    return data;
  },

  async confirmCode({ state, rootState, commit, dispatch }, payload) {
    const { profile, access_token } = await network.post(
      "/v1/passport/oauth/phone/verify",
      payload,
    );

    commit("SET_AUTH", { profile, accessToken: access_token });

    dispatch("verify");
  },

  async update({ state, rootState, commit, dispatch }, payload) {
    await network.put("v1/passport/oauth/user/profile", payload);
    const profile = await dispatch("verify");

    if (profile) {
      commit("SET_AUTH", { profile });
    }
  },

  logout({ state, rootState, commit, dispatch }, payload) {
    commit("UNSET_AUTH");
  },
};

const store: Module<AuthState, State> = {
  namespaced: true,
  state: new AuthState(),
  getters,
  mutations,
  actions,
};

export default store;
