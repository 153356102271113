<template>
  <div
    class="ui-checkbox"
    :class="{
      hover: state.hover,
      checked: model === true,
      error: error,
      disabled: disabled,
      readonly: readonly,
    }"
  >
    <div class="ui-checkbox__body">
      <input
        :id="`checkbox-${label}`"
        ref="input"
        v-model="model"
        type="checkbox"
        :readonly="readonly"
        :disabled="disabled"
        :true-value="true"
        :false-value="false"
      />
      <label ref="hover" :for="`checkbox-${label}`" class="ui-checkbox__label">
        {{ label }}
      </label>
    </div>
    <div v-if="error" class="ui-checkbox__error">{{ error }}</div>
  </div>
</template>

<script>
import { isMobile } from "@/composable/device";

export default {
  name: "UiCheck",

  props: {
    modelValue: Boolean,
    label: String,
    error: String,
    disabled: Boolean,
    readonly: Boolean,
  },

  emits: ["update:modelValue"],

  data() {
    const state = { hover: false, error: false };
    return { state };
  },

  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },

  mounted() {
    if (!isMobile) {
      this.$refs.hover.addEventListener("mouseover", (e) => {
        this.state.hover = true;
      });
      this.$refs.hover.addEventListener("mouseout", (e) => {
        this.state.hover = false;
      });
    }
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.ui-checkbox {
  &__body {
    display: flex;
    align-items: flex-start;
    gap: unit(1.2);

    input {
      display: inline-block;
      width: unit(2.4);
      height: unit(2.4);
      border: solid 1px #00e1ff;
      border-radius: 50%;

      &:checked {
        &::before {
          content: "";
          @include abs-center;
          width: unit(1.6);
          height: unit(1.6);
          border-radius: 50%;
          background: #00e1ff;
        }
      }
    }

    label {
      display: inline;
      color: $color-black;
      font-size: unit(1.4);
      line-height: unit(2.4);
      font-weight: inherit;
    }
  }
}
</style>
