import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import routeRecords from "./routes";
import authenticate from "./middlewares/authentication";

const routes: Array<RouteRecordRaw> = routeRecords;

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  authenticate(to, from, next);
});

export default router;
