import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isToday from "dayjs/plugin/isToday";

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(isToday);
dayjs.extend(isSameOrBefore);

export default dayjs;
