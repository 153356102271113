<template>
  <li class="dialog-item">
    <div class="container">
      <div class="dialog-body">{{ data.message }}</div>
      <div class="dialog-footer">
        <ui-button
          v-if="data.false"
          size="small"
          color="black"
          class="button button--true"
          @click="callback('false')"
          >{{ data.false.label }}
        </ui-button>
        <ui-button
          v-if="data.true"
          size="small"
          color="primary"
          class="button button--false"
          @click="callback('true')"
          >{{ data.true.label }}</ui-button
        >
        <ui-button
          v-if="data.ok"
          size="small"
          color="primary"
          class="button button--ok"
          @click="callback('ok')"
          >{{ data.ok.label }}</ui-button
        >
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: "DialogItem",
  props: ["data"],
  mounted() {
    this.$watch("$route", (a, b) => {
      this.removeItem();
    });
  },
  methods: {
    removeItem() {
      this.$store.commit("dialog/REMOVE_ITEM", this.data);
    },
    callback(response) {
      this.data[response].callback();
      this.removeItem();
    },
  },
};
</script>

<style scoped lang="scss">
.dialog-item {
  @include fsf;
  padding: unit(4.8) unit(2.4);
  background-color: $color-shadow;
  z-index: 1600;
  @include vertical-center-container("& > .container", $site-width-h);

  & > .container {
    @include shadow-box($color-shadow);
  }

  .dialog-body {
    font-size: unit(1.4);
    padding: unit(2.4);
  }

  .dialog-footer {
    border-top: 1px solid $color-gray-1;
    text-align: right;
    line-height: 0;
    @include cl-last;

    .button {
      font-size: unit(1.4);
      font-weight: 500;
      color: $color-primary;

      &--true {
        color: $color-primary;
      }

      &--false {
        color: $color-gray-6;
      }
    }
  }
}
</style>
