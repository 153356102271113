<template>
  <div id="wrapper">
    <Suspense>
      <template #default>
        <router-view :key="key" />
      </template>
      <template #fallback>
        <ui-lottie
          ref="lottie-loading"
          :animation-data="loadingPrimary"
          :animating="true"
          :loop="true"
          :width="200"
          :height="200"
        />
      </template>
    </Suspense>
  </div>
</template>

<script>
import { computed } from "@vue/reactivity";
import { useRoute } from "vue-router";
import loadingPrimary from "@/assets/animations/loading-primary.json";

export default {
  name: "BlankView",
  setup() {
    return {
      loadingPrimary,
      key: computed(() => useRoute().fullPath),
    };
  },
};
</script>
