import { Module, ActionContext, GetterTree } from "vuex";
import { State } from "../typings/index";

(() => {
  function setVhSize() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  setVhSize();

  window.addEventListener("resize", setVhSize);
})();

const LAYOUT_MODE = {
  MOBILE: "MOBILE",
  PC: "PC",
} as const;

export class Layout {
  layoutMode: keyof typeof LAYOUT_MODE = LAYOUT_MODE.PC;

  isSidebarOpened: Boolean = false;
}

const getters: GetterTree<Layout, State> = {
  isSidebarOpened: (state) => state.isSidebarOpened,
};

export const layout: Module<Layout, State> = {
  namespaced: true,
  state: new Layout(),
  mutations: {
    OPEN_SIDEBAR(state) {
      state.isSidebarOpened = true;
    },
    CLOSE_SIDEBAR(state) {
      state.isSidebarOpened = false;
    },
    SET_LAYOUT_MODE<T extends keyof typeof LAYOUT_MODE>(
      state,
      mode: typeof LAYOUT_MODE[T],
    ) {
      state.layoutMode = mode;
    },
  },
  getters,
};

export default layout;
