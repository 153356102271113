import { App, Plugin, Component } from "vue";
import image from "./component/image.vue";
import background from "./component/background.vue";

/**
 * Merges plugin installation options with a component
 * @param  {VueComponent} component
 * @param  {Object} options
 * @return {VueComponent}
 */
const mergeOptions = (component: Component, options = {}) => {
  return {
    ...component,
    data: () => ({ options }),
  };
};

/**
 * Vue installation method
 * @param  {VueInstance} Vue
 * @param  {Object} [options={}]
 */
const install = (app: App, options?: any) => {
  app.component("progressive-img", mergeOptions(image, options));
  app.component("progressive-background", mergeOptions(background, options));
};

const ProgressivePlugin: Plugin = {
  install,
};

export default ProgressivePlugin;
