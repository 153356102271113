<template>
  <li class="alert-item" :class="data.status">
    <div class="container">
      <div class="close" @click="removeItem">×</div>
      <div class="alert-body">{{ message }}</div>
    </div>
  </li>
</template>

<script>
export default {
  name: "AlertItem",
  props: ["data"],
  data() {
    return {
      message: null,
    };
  },
  created() {
    console.log(this.data);
    if (typeof this.data.message === "string") {
      this.message = this.$__t(this.data.message);
    } else if (this.data.message.data && this.data.message.data.error) {
      this.message = this.$__t(this.data.message.data.error);
    } else if (this.data.message.error_msg) {
      this.message = this.$__t(this.data.message.error_msg);
    } else if (this.data.message.details) {
      this.message = this.$__t(this.data.message.details);
    } else if (!this.data.message) {
      this.message = "Unknown Error";
    }
    if (!this.message && typeof this.data.message === "object") {
      this.message = this.data.message.toString();
    }
  },
  mounted() {
    if (this.data.duration) {
      if (this.data.duration > 0) {
        setTimeout(this.removeItem, this.data.duration);
      }
    } else {
      setTimeout(this.removeItem, 3500);
    }
  },
  methods: {
    removeItem() {
      this.$store.commit("alert/REMOVE_ITEM", this.data);
    },
  },
};
</script>
