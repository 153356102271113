<template>
  <div class="search-input">
    <div class="label">
      <label v-if="label" :for="id">{{ label }} </label>
    </div>
    <div class="body">
      <div class="container">
        <div class="input-icon">
          <svg-icon name="search-primary" class="icon" />
        </div>
        <input
          :id="id"
          v-model="model"
          type="text"
          class="input"
          :placeholder="placeholder"
          :readonly="readonly"
          :disabled="disabled"
          @keyup="$emit('keyup', $event.target.value)"
          @focus="state.focus = true"
          @blur="state.focus = false"
          @mouseover="state.hover = true"
          @mouseleave="state.hover = false"
        />
      </div>
    </div>
    <div class="error">
      {{ error }}
    </div>
  </div>
</template>

<script>
import md5 from "md5";
import { computed, reactive, toRefs } from "@vue/reactivity";

export default {
  name: "IconInput",
  props: {
    id: {
      type: String,
      default: `icon-input-${md5(+new Date())}`,
    },
    label: String,
    placeholder: String,
    modelValue: String,
    error: String,
    readonly: Boolean,
    disabled: Boolean,
  },
  emits: ["updateModelValue", "keyup"],

  setup(props, { emit }) {
    const { modelValue } = toRefs(props);

    const state = reactive({
      fill: false,
      focus: false,
      hover: false,
    });

    const model = computed({
      get() {
        if (modelValue.value) {
          state.fill = true;
        }

        return modelValue.value;
      },

      set(value) {
        emit("updateModelValue", value);
      },
    });

    return {
      model,
      state,
    };
  },
};
</script>

<style lang="scss" scoped>
.search-input {
  .label {
    font-size: unit(1.2);
    color: #61676c;
  }

  .body {
    > .container {
      border: unit(0.2) solid #00e1ff;
      border-radius: unit(5);
      padding: unit(1) unit(1.6);
      display: flex;
      gap: unit(0.6);
    }

    .input-icon {
      width: unit(2.4);
      height: unit(2.4);

      .icon {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .input {
      font-size: unit(1.4);
      color: $color-black;

      &::placeholder {
        color: #61676c;
        font-weight: 400;
      }
    }
  }
}
</style>
