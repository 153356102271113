import { App, Component, Plugin } from "vue";

// Cookie
import { VueCookieNext } from "vue-cookie-next";

import VueWNInterface, { WNInterface } from "vue-wni";
import validator from "validator";
import _ from "lodash";
import Vue3Resize from "vue3-resize";

// Mask
import VueTheMask from "vue-the-mask";

// Clipboard
import VueClipboard from "vue-clipboard2";

// Validator
import VueValidator from "./validator";

// Progressive Image
import VueProgressiveImage from "./progressive";

// Resize
import "vue3-resize/dist/vue3-resize.css";

// Lottie
import VueLottie from "./lottie";

// Toast
import Toast from "./vue3-toast";

// Channel talk
import VueChannelTalk from "./channel-talk";

// Customs
import VueSvgIcon from "./svg-icon";

// Utils
import dayjs from "./dayjs";
import { LanguagePlugin } from "@/plugins/language";
// import utils from './utils';

// Mobidic Map
import { plugin as MobidicMap } from "./mobidic-map";

// // Analytics
// import VueAnalytics from './analytics';

// // Customs
// import VueSvgIcon from './svg-icon';

// import VueMarkdown from './markdown';

// // Material
// import VueMaterial from './material';

// // Pulldown
// import VuePulldown from './pulldown';

// Realtime
// import VueRealtime from "./realtime";

// Swiper
// import Swiper from './swiper';

// Billboard.js
// import Billboard from "./billboard";
// import './billboard';

// Global Components
// import Btn from '@/components/atoms/button/Btn.vue';

declare global {
  interface Window {
    naver: any;
    google: any;
  }
}

const plugin = {
  install: (app: App, options: any) => {
    // smoothscroll.polyfill();

    VueCookieNext.config({ expire: "7d", path: "/" });
    VueClipboard.config.autoSetContainer = true;

    app.use(VueProgressiveImage, {
      cache: true,
      delay: 0,
    });
    app.use(VueCookieNext);
    app.use(Vue3Resize);
    app.use(VueSvgIcon);
    app.use(VueTheMask);
    app.use(VueValidator);
    app.use(VueClipboard);

    app.provide("$copyText", app.config.globalProperties.$copyText);

    // app.use(VueAnalytics, {
    //   type: isShareLink,
    // });

    // app.use(VueSvgIcon);
    app.use(VueWNInterface);
    // app.use(VueMarkdown);

    // app.use(VueMaterial);
    // app.use(VuePulldown);
    app.use(VueLottie);

    // app.use(Swiper);

    app.use(Toast, {
      position: "top",
      maxToasts: 1,
      duration: 2000,
    });

    app.use(VueChannelTalk);

    app.use(LanguagePlugin);

    app.use(MobidicMap);

    // app.directive("click-outside", {
    //   beforeMount: (el, binding, vnode) => {
    //     el.clickOutsideEvent = function(event) {
    //       // here I check that click was outside the el and his childrens
    //       if (!(el == event.target || el.contains(event.target))) {
    //         // and if it did, call method provided in attribute value
    //         binding.instance[binding.expression](event);
    //       }
    //     };
    //     document.body.addEventListener("click", el.clickOutsideEvent);
    //   },
    //   unmounted: function(el) {
    //     document.body.removeEventListener("click", el.clickOutsideEvent);
    //   },
    // });

    app.mixin({
      created() {
        const { tag } = this.$options;
        if (tag) {
          // console.log(tag);
        }
      },
    });

    app.provide("$_", _);
    app.provide("$dayjs", dayjs);
    // eslint-disable-next-line no-underscore-dangle
    app.config.globalProperties.$_ = _;
    app.config.globalProperties.$dayjs = dayjs;
  },
};

export default plugin;
