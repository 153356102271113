import axios from "axios";
import compareVersions from "compare-versions";
import { register } from "register-service-worker";
import config from "@/config";

const debug = require("debug")("register.service.worker");

class ServiceWorker {
  private interval: any;

  loadManifest() {
    return new Promise((resolve, reject) => {
      axios
        .get(`/manifest.json?r=${Math.random()}`)
        .then((response) => {
          if (response.data.version) {
            resolve(response.data);
          } else {
            const error = new Error("version is undefined");
            reject(error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  refreshVersion(reload: Boolean) {
    debug("Content is unregistering.");

    if ("serviceWorker" in window.navigator) {
      window.navigator.serviceWorker.ready.then((registration) => {
        registration.unregister().then(() => {
          debug("Service worker has been unregistered.");
          if (reload === true) {
            window.location.reload();
          }
        });
      });

      if (reload === true) {
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } else if (reload === true) {
      window.location.reload();
    }
  }

  registerServiceWorker() {
    if (process.env.NODE_ENV === "production") {
      register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
          debug(
            "App is being served from cache by a service worker.\n" +
              "For more details, visit https://goo.gl/AFskqB",
          );
        },
        registered() {
          debug("Service worker has been registered.");
        },
        cached() {
          debug("Content has been cached for offline use.");
        },
        updatefound() {
          debug("New content is downloading.");
        },
        updated() {
          // debug('New content is available; please refresh.')
        },
        offline() {
          debug(
            "No internet connection found. App is running in offline mode.",
          );
        },
        error(error) {
          debug.error("Error during service worker registration:", error);
        },
      });
    }
  }

  initialize() {
    config.printVersion();

    if (process.env.NODE_ENV !== "production") {
      debug("service worker is disabled.");
      return;
    }

    debug("initialize");

    this.loadManifest()
      .then((data: any) => {
        const manifestVersion = data.version;
        const storeVersion = localStorage.getItem("__VERSION__");

        let versionString = "";
        versionString += `Local Version: ${config.version}`;
        versionString += ` / Manifest Version: ${manifestVersion}`;
        versionString += ` @(${storeVersion})`;

        if (config.version !== manifestVersion) {
          if (storeVersion === manifestVersion) {
            debug("Detect new version. try to refresh this page");
            return Promise.reject(new Error("need_update"));
          }

          debug("Detect new version. refreshing this page");
          localStorage.setItem("__VERSION__", manifestVersion);
          return this.refreshVersion(true);
        }

        if (!storeVersion) {
          debug("First Version");
          localStorage.setItem("__VERSION__", manifestVersion);
        }

        this.startVersionCheck();

        return this.registerServiceWorker();
      })
      .catch((e) => {
        debug("error", e);
      });
  }

  startVersionCheck() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }

    this.interval = setInterval(() => {
      this.loadManifest().then((data: any) => {
        const minimumVersion = data["minimum-version"];
        if (compareVersions(config.version, minimumVersion) < 0) {
          this.refreshVersion(true);
        }
      });
    }, 1000 * 60);
  }
}

const serviceWorker = new ServiceWorker();
serviceWorker.initialize();
export default serviceWorker;
