import { render, h } from "vue";

const createElement = () =>
  typeof document !== "undefined" && document.createElement("div");

const mount = (component: any, options: any = {}) => {
  const { props, children, element, app } = options;

  let el = element || createElement();

  let vNode: any = h(component, props, children);
  // eslint-disable-next-line no-underscore-dangle
  if (app && app._context) {
    // eslint-disable-next-line no-underscore-dangle
    vNode.appContext = app._context;
  }

  render(vNode, el);

  const destroy = () => {
    if (el) {
      render(null, el);
    }
    el = null;
    vNode = null;
  };

  return { vNode, destroy, el };
};

export default mount;
