<template>
  <div
    class="ui-auth-textbox"
    :class="{
      hover: state.hover,
      focus: state.focus,
      fill: state.fill,
      error: error,
      disabled: disabled,
      readonly: readonly,
    }"
  >
    <label ref="hover">
      <div class="label">
        {{ label }}
        <span v-if="required" class="required">*</span>
      </div>
      <div class="body">
        <input
          ref="input"
          :type="inputType"
          :placeholder="state.focus ? placeholder : ''"
          :value="modelValue"
          :readonly="readonly"
          :disabled="disabled"
          autocapitalize="off"
          autocomplete="off"
          :maxlength="maxLength"
          :inputmode="inputMode"
          @input="$emit('update:modelValue', $event.target.value)"
          @focus="onFocus"
          @blur="onBlur"
        />
        <div v-if="timer" class="utils">
          <div class="utils__timer">{{ timer }}</div>
          <span> | </span>
          <button class="utils__btn" @click="onClickRequestButton">
            재전송
          </button>
        </div>
      </div>
    </label>
    <div v-if="error" class="error">{{ error }}</div>
  </div>
</template>

<script>
import { createContextInterval, removeContextInterval } from "@/helpers";

export default {
  name: "UiAuthTextbox",
  props: {
    modelValue: String,
    type: {
      type: String,
      default: "text",
    },
    placeholder: String,
    label: String,
    error: String,
    disabled: Boolean,
    maxLength: Number,
    readonly: Boolean,
    inputMode: String,
    required: Boolean,
    status: String, // unset, sending, sent
    expiration: {
      type: Number,
      default: 3 * 60 * 1000,
    },
  },
  emits: ["focus", "blur", "update:modelValue", "request"],
  data() {
    const state = {
      hover: false,
      focus: false,
      fill: false,
      error: false,
    };
    return { state, duration: null };
  },
  computed: {
    inputType() {
      return this.type ?? "text";
    },

    timer() {
      if (this.$dayjs.isDuration(this.duration)) {
        return this.duration.format("mm:ss");
      }

      return "0:00";
    },
  },
  watch: {
    modelValue: {
      handler(value) {
        this.state.fill = !!value;
      },
      immediate: true,
    },

    status(newValue, oldValue) {
      if (newValue === "sent") {
        this.startTimer();
      }
    },
  },
  mounted() {
    if (this.$store.getters["layout/isTouchDevice"]) {
      this.$refs.hover.addEventListener("mouseover", (e) => {
        this.state.hover = true;
      });
      this.$refs.hover.addEventListener("mouseout", (e) => {
        this.state.hover = false;
      });
    }

    if (this.status === "sent") {
      this.startTimer();
    }
  },

  beforeDestroy() {
    removeContextInterval(this, "auth-timer");
  },

  methods: {
    onFocus(e) {
      if (this.readonly === true) {
        return;
      }
      this.state.focus = true;
      this.$emit("focus", e);
    },
    onBlur(e) {
      if (this.readonly === true) {
        return;
      }
      this.state.focus = false;
      this.$emit("blur", e);
    },

    startTimer() {
      this.duration = this.$dayjs.duration(this.expiration);

      createContextInterval.call(
        this,
        "auth-timer",
        () => {
          this.duration = this.duration.subtract(1, "second");

          if (this.duration.asMilliseconds() < 1000) {
            removeContextInterval.call(this, "auth-timer");
          }
        },
        1000,
      );
    },

    onClickRequestButton() {
      removeContextInterval.call(this, "auth-timer");
      this.$emit("request");
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-auth-textbox {
  font-size: unit(1.4);
  line-height: 2;
  color: $color-black !important;
  cursor: text;

  .label {
    position: absolute;
    top: 0;
    left: 0;
    height: unit(5.4);
    padding: unit(1.8) 0 unit(0.6) 0;
    white-space: nowrap;
    transition: all 0.2s;
  }

  .body {
    display: flex;
    gap: unit(0.4);
  }

  .required {
    font-size: inherit;
    color: $color-red;
    margin: 0 unit(0.2);
  }

  input {
    height: unit(5.4);
    padding-top: unit(2);
    transition: all 0.2s;
  }

  .utils {
    display: flex;
    justify-content: space-between;
    gap: unit(0.4);
    align-items: flex-end;

    > * {
      color: #00e1ff !important;
    }

    &__timer {
      font-weight: 500;
    }

    &__btn {
      cursor: pointer;
      font-weight: 500 !important;
    }
  }

  & > .error {
    font-size: unit(1.2);
    color: $color-red;
  }
  & {
    .label {
      color: $color-gray-5;
    }
    input {
      border-bottom: solid 1px $color-gray-3;
    }
  }
  // &.hover {
  //   input {
  //     background-color: $color-black;
  //   }
  // }
  &.focus {
    .label {
      color: $color-black;
    }
    input {
      border-bottom: solid 1px $color-black;
    }
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    .label {
      color: $color-gray-5;
    }
    input {
      background-color: $color-gray-1;
      border-bottom: solid 1px $color-gray-3;
    }
  }
  &.readonly {
    opacity: 0.8;
    .label {
      color: $color-gray-5;
    }
    input {
      background-color: $color-gray-0;
      border-bottom: solid 1px $color-gray-3;
    }
  }
  &.focus,
  &.fill {
    .label {
      font-size: unit(1.2);
      line-height: 2;
      padding: 0;
      height: 0;
    }
  }

  &.border {
    .label {
      position: relative;
      height: unset;
      padding: unit(0.4) unit(0.8);
      color: $color-black;
      font-size: unit(1.4);
      font-weight: bold;
      white-space: nowrap;
    }

    input {
      border: solid 1px $color-gray-3;
      border-radius: $radius;
      padding: unit(1.6) unit(0.8) unit(1.6) unit(0.8);
      line-height: 3;

      &::placeholder {
        color: $color-gray-5;
      }
    }

    &.fill,
    &.focus {
      input {
        border: solid 1px $color-black;
      }
    }
  }
}
</style>
