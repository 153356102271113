<template>
  <div class="resource space-slider">
    <ui-button touchEffect class="space-slider__button" @click="goToSpacePage">
      <space-card :id="data.id" class="space-card"></space-card>
    </ui-button>
  </div>
</template>

<script>
import { onBeforeMount, onMounted } from "vue";

import { computed, ref, toRefs } from "@vue/reactivity";
import { useRouter } from "vue-router";

import SpaceCard from "./SpaceCard.vue";

export default {
  components: { SpaceCard },
  props: ["data"],

  setup(props, { emit }) {
    const { data } = toRefs(props);

    const router = useRouter();
    const goToSpacePage = () => {
      router.push({
        name: "SpaceSingle",
        params: { id: data.value.id },
      });
    };

    return {
      goToSpacePage,
    };
  },
};
</script>

<style lang="scss" scoped>
.space-slider {
  &__button {
    padding: 0 !important;
    display: block !important;
    margin: auto;
    text-align: left !important;
  }

  .space-card {
    padding: unit(2) 0 !important;
  }
}
</style>
