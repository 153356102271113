<template>
  <div
    class="sidebar"
    :class="{ opened: isSidebarOpened }"
    @click="closeSidebar"
  >
    <div class="sidebar-container" @click.stop="">
      <div class="sidebar-content">
        <h2 class="sidebar-content__header">
          <ui-button class="profile-button" touchEffect @click="goToMyPage">
            <span class="profile-text">
              <template v-if="isLoggedIn"> {{ userName }} </template>
              <template v-else> 로그인 </template>
            </span>
          </ui-button>
        </h2>

        <ul class="sidebar-content__list">
          <li class="sidebar-content__list-item" @click="onClickAnchor">
            <router-link :to="{ name: 'Spaces' }">
              <span class="list-text">지도로 공간 찾기</span>
            </router-link>
          </li>
          <li class="sidebar-content__list-item" @click="onClickAnchor">
            <a :href="sellerLandingURL">
              <span class="list-text">유후 셀러 신청</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/reactivity";
import { watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import config from "@/config";
import { isLoggedIn, profile } from "@/composable/auth";
import { isSidebarOpened, closeSidebar } from "@/composable/layout";

export default {
  name: "SidebarContainer",

  setup() {
    const userName = computed(() => profile.value?.nickname);
    const router = useRouter();

    const goToMyPage = () => {
      router.push({ name: "MyPage" });
    };

    watch(
      () => router.currentRoute.value.fullPath,
      (_) => {
        closeSidebar();
      },
    );

    const onClickAnchor = () => {
      setTimeout(() => {
        closeSidebar();
      }, 0);
    };

    return {
      isLoggedIn,
      profile,
      userName,
      isSidebarOpened,
      closeSidebar,
      sellerLandingURL: config.get("sellerLandingURL"),
      goToMyPage,
      onClickAnchor,
    };
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  z-index: 1700;
  pointer-events: none;

  &::before {
    content: "";
    @include fsf;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }

  &-container {
    position: fixed;
    top: 0;
    left: 0;
    will-change: transform;
    transform: translate(-100%);
    height: 100%;
    width: unit(25);
    background: $color-white;
    transition: transform 0.3s ease-out;
  }

  &-content {
    margin: unit(6.6) 0;
    padding: 0 unit(1.6);

    &__header {
      text-decoration-line: underline;
      text-align: left;

      .profile-button {
        width: auto !important;
        padding: 0 !important;
      }

      .profile-text {
        font-weight: 700;
        font-size: unit(2);
      }
    }

    &__list {
      margin-top: unit(4);
    }

    &__list-item {
      height: unit(4);
      padding: unit(1) 0;

      a {
        @include fsa;
      }

      .list-text {
        font-weight: 400;
        font-size: unit(1.4);
        line-height: 1.43;
      }
    }
  }

  &.opened {
    width: 100%;
    height: 100%;
    pointer-events: all;

    &::before {
      opacity: 1;
    }

    & {
      > .sidebar-container {
        transform: translate(0);
      }
    }
  }
}
</style>
