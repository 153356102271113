<template>
  <ui-form
    ref="refForm"
    :formData="formData"
    :ignoreSubmit="true"
    class="user-terms-agree-form"
    @submit="submitForm"
    @error="onError"
  >
    <ui-form-list class="form-list">
      <ui-form-list-item>
        <ui-checkbox
          v-model="checkAll.value"
          :type="checkAll.type"
          :label="checkAll.label"
          class="bold-label"
        >
        </ui-checkbox>
      </ui-form-list-item>
      <ui-form-list-item class="form-list-item--nested">
        <ui-checkbox
          v-model="formData.term_1.value"
          :type="formData.term_1.type"
          :label="formData.term_1.label"
        >
          <!-- <span class="label">{{ formData.term_1.label }}</span> -->
        </ui-checkbox>
      </ui-form-list-item>
      <ui-form-list-item class="form-list-item--nested">
        <ui-checkbox
          v-model="formData.term_2.value"
          :type="formData.term_2.type"
          :label="formData.term_2.label"
        >
          <!-- <span class="label">{{ formData.term_2.label }}</span> -->
        </ui-checkbox>
      </ui-form-list-item>
      <ui-form-list-item class="form-list-item--nested">
        <ui-checkbox
          v-model="formData.optional_ad.value"
          :type="formData.optional_ad.type"
          :label="formData.optional_ad.label"
        >
          <!-- <span class="label">{{ formData.optional_ad.label }}</span> -->
        </ui-checkbox>
      </ui-form-list-item>

      <ui-form-list-item class="form-list-item--submit">
        <div class="submit-area">
          <ui-button
            touchEffect
            class="btn btn--submit"
            @click="$refs.refForm.submit()"
          >
            다음
          </ui-button>
        </div>
      </ui-form-list-item>
    </ui-form-list>
  </ui-form>
</template>

<script>
import { reactive, watch, watchEffect } from "vue";
import { useRouter } from "vue-router";
import { createAlert } from "@/composable/layout";

export default {
  name: "UserTermsAgree",

  emits: ["agree"],

  setup(props, { emit }) {
    const checkAll = reactive({
      type: "checkbox",
      value: false,
      label: "모두 동의합니다",
    });

    const formData = reactive({
      term_1: {
        type: "checkbox",
        value: false,
        label: "[필수] 개인정보 수집 및 이용약관 동의",
        expected: true,
        validation: [{ type: "required" }],
      },
      term_2: {
        type: "checkbox",
        value: false,
        label: "[필수] 위치기반 서비스 이용약관 동의",
        expected: true,
        validation: [{ type: "required" }],
      },
      optional_ad: {
        type: "checkbox",
        value: false,
        label: "[선택] 광고성 정보 수신 동의",
      },
    });

    const onError = () => {
      createAlert({
        message: "필수 항목에 모두 동희해주세요.",
        status: "info",
      });
    };

    const submitForm = ({ term_1, term_2, optional_ad }) => {
      emit("agree", { term_1, term_2, optional_ad });
    };

    watch(
      () => checkAll.value,
      (newValue) => {
        if (newValue) {
          Object.keys(formData).forEach((key) => {
            formData[key].value = true;
          });
        }
      },
    );

    watchEffect(() => {
      const allChecked = Object.keys(formData).every(
        (key) => !!formData[key]?.value,
      );

      checkAll.value = !!allChecked;
    });

    return {
      checkAll,
      formData,
      submitForm,
      onError,
    };
  },
};
</script>

<style lang="scss" scoped>
.user-terms-agree-form {
  .btn {
    &--submit {
      height: unit(4.4);
      color: $color-white;
      font-size: unit(1.4) !important;
      font-weight: bold;
      background: $color-black;
      border-radius: unit(2);
    }
  }

  .form-title {
    margin-top: unit(4.4);
    font-size: unit(1.6);
    font-weight: bold;
  }

  .form-list-item {
    &--nested {
      padding-left: unit(3.6);
    }

    &--submit {
      margin-top: unit(4.4) !important;
    }
  }

  :deep(.bold-label) {
    label {
      font-weight: bold;
    }
  }

  &--page {
    display: flex;
    flex: 1;

    .form-list {
      display: flex;
      flex: 1;
      flex-direction: column;
    }

    .form-list-item {
      &--submit {
        margin-top: auto !important;
        padding-top: unit(2.4);
        margin-bottom: 0;
      }
    }
  }
}
</style>
