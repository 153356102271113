<template>
  <div
    class="ui-select"
    :class="{ disabled: disabled, fill: fill, border: border }"
  >
    <label>
      <div class="label">{{ label }}</div>
      <div class="body">
        <i class="fa fa-angle-down"></i>
        <select
          ref="input"
          :value="modelValue"
          :disabled="disabled"
          @change="$emit('update:modelValue', $event.target.value)"
        >
          <option v-if="placeholder" value="" disabled>
            {{ placeholder }}
          </option>
          <option
            v-for="(item, index) in items"
            :key="index"
            :value="item.value"
          >
            {{ item.label }}
          </option>
        </select>
      </div>
    </label>
    <div v-if="error" class="error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  name: "UiSelect",
  props: [
    "modelValue",
    "type",
    "placeholder",
    "label",
    "error",
    "disabled",
    "maxlength",
    "items",
    "border",
  ],
  emits: ["update:modelValue"],
  computed: {
    fill() {
      return !!this.modelValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-select {
  font-size: unit(1.4);
  line-height: 2;
  color: $color-gray-5;
  cursor: text;

  .label {
    position: absolute;
    top: 0;
    left: 0;
    height: unit(5.4);
    padding: unit(1.8) 0 unit(0.6) 0;
    white-space: nowrap;
    transition: all 0.2s;
  }

  .required {
    font-size: inherit;
    color: $color-red;
    margin: 0 unit(0.2);
  }

  select {
    height: unit(5.4);
    padding: unit(1.8) 0 unit(0.6) 0;
    transition: all 0.2s;
  }

  & > .error {
    font-size: unit(1.2);
    color: $color-red;
  }

  & {
    .label {
      color: $color-gray-5;
    }
    select {
      border-bottom: solid 1px $color-gray-3;
    }

    i {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      padding: 0 unit(1.6);
    }
  }

  &.focus {
    .label {
      color: $color-primary;
    }
    select {
      border-bottom: solid 1px $color-primary;
    }
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    .label {
      color: $color-gray-5;
    }
    select {
      background-color: $color-gray-1;
      border-bottom: solid 1px $color-gray-3;
    }
  }
  &.readonly {
    opacity: 0.8;
    .label {
      color: $color-gray-5;
    }
    select {
      background-color: $color-gray-0;
      border-bottom: solid 1px $color-gray-3;
    }
  }
  &.focus,
  &.fill {
    color: $color-black;

    .label {
      font-size: unit(1.2);
      line-height: 2;
      padding: 0;
      height: 0;
    }
  }

  &.border {
    .label {
      position: relative;
      height: unset;
      padding: unit(0.4) unit(0.8);
      color: $color-black;
      font-size: unit(1.4);
      font-weight: bold;
      white-space: nowrap;
    }

    select {
      border: solid 1px $color-gray-3;
      border-radius: $radius;
      padding: unit(1.6) unit(0.8) unit(1.6) unit(0.8);
    }

    &.fill,
    &.focus,
    &.hover {
      border: solid 1px $color-primary;
    }
  }
}
</style>
