import "@/assets/icons";
import VueSvgIcon from "./lib/SvgIcon.vue";

const plugin = {
  install: (app, options) => {
    // app.component('svg-icon', VueSvgIcon);
    app.component("SvgIcon", VueSvgIcon);
  },
};

// typeof window.Vue === 'function' && window.Vue.use(plugin)

export default plugin;
