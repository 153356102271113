import { Component, computed } from "vue";
import $store from "@/store";

export enum AlertStatus {
  SUCCESS = "success",
  INFO = "info",
  ERROR = "error",
}

export enum ModalSize {
  FULL = "full",
  BIG = "big",
  MEDIUM = "medium",
  SMALL = "small",
}

type Modal = {
  component: String;
  size?: ModalSize;
  data?: any;
};

export const createModal = (options: {
  component: any;
  size?: ModalSize;
  data?: any;
}) => {
  $store.commit("modal/ADD_ITEM", options);
};

export const removeModal = (options: Modal) => {
  $store.commit("modal/REMOVE_ITEM", options);
};

export const createSlider = (options: { component: any; data?: any }) => {
  $store.commit("slider/ADD_ITEM", options);
};

export const removeSlider = (options: { component: any; data?: any }) => {
  $store.commit("slider/REMOVE_ITEM", options);
};

export const createFullScreen = (options: { component: any; data?: any }) => {
  $store.commit("fullscreen/ADD_ITEM", options);
};
export const createDialog = (options: {
  message: string;
  true: { label: string; callback: Function };
  false?: { label: string; callback: Function };
}) => {
  $store.commit("dialog/ADD_ITEM", options);
};
export const createAlert = (options: {
  message: string;
  status: AlertStatus;
  duration?: number;
}) => {
  $store.commit("alert/ADD_ITEM", options);
};

export const openSidebar = () => {
  $store.commit("layout/OPEN_SIDEBAR");
};

export const closeSidebar = () => {
  $store.commit("layout/CLOSE_SIDEBAR");
};

export const isSidebarOpened = computed(
  () => $store.getters["layout/isSidebarOpened"],
);

// export const isMobileLayout =
