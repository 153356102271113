export const removeElement = (el: HTMLElement) => {
  if (typeof el.remove !== "undefined") {
    el.remove();
  } else if (el.parentNode) {
    el.parentNode.removeChild(el);
  }
};

export default {
  removeElement,
};
