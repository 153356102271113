class Event {
  private queue: any = {};

  $on(name: string, callback: Function) {
    this.queue[name] = this.queue[name] || [];
    this.queue[name].push(callback);
  }

  $off(name: string, callback: Function) {
    if (this.queue[name]) {
      for (let i = 0; i < this.queue[name].length; i += 1) {
        if (this.queue[name][i] === callback) {
          this.queue[name].splice(i, 1);
          break;
        }
      }
    }
  }

  $emit(name: string, data?: any) {
    if (this.queue[name]) {
      this.queue[name].forEach((callback: Function) => {
        callback(data);
      });
    }
  }
}

export default new Event();
