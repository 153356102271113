import { RouteLocationNormalized } from "vue-router";
import { isLoggedIn, profile } from "@/composable/auth";
import { isMobile } from "@/composable/device";
import { createModal, ModalSize } from "@/composable/layout";

import router from "@/router";

export default function authenticateHandler(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: any,
) {
  const { level, guest } = to.meta;

  if ((level as Number) > 0 && !isLoggedIn.value) {
    if (isMobile) {
      next({
        name: "Login",
        query: { redirect: router.currentRoute.value.fullPath },
        replace: true,
      });
    } else {
      createModal({ component: "UserLoginModal", size: ModalSize.BIG });
      next("/");
    }

    return;
  }

  if (guest && isLoggedIn.value) {
    next(false);

    return;
  }

  next(true);
}
