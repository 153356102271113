import validator from "validator";

const plugin = {
  install: (app, options) => {
    app.provide("$validator", validator);
    app.config.globalProperties.$validator = validator;
  },
};

export default plugin;
