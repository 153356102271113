import loadScript from "load-script";
import { App } from "vue";
import config from "@/config";

declare global {
  interface Window {
    ChannelIO: any;
  }
}

export const ChannelTalk = {
  pluginKey: config.get("channelTalkKey"),
  mobileOpenUi: "iframe", // [newTab, iframe]'
  initiated: false,
  isShow: false,

  execute(action: any, options?, callback?: () => void | any) {
    if (window.ChannelIO) {
      window.ChannelIO(action, options, () => {
        if (callback) {
          callback();
        }
      });
      return true;
    }
    return false;
  },

  init() {
    this.initiated = true;
  },

  show(options: any = {}) {
    // eslint-disable-next-line camelcase
    const { el, callback, gid, user_name } = options;
    const option: any = {
      pluginKey: this.pluginKey,
      mobileOpenUI: this.mobileOpenUi,
      memberId: gid || "",
      profile: {
        // eslint-disable-next-line camelcase
        name: user_name || "",
      },
    };

    if (el) {
      option.customLauncherSelector = el;
      option.hideChannelButtonOnBoot = true;
    }

    if (callback) {
      this.execute("boot", option, callback);
    } else {
      this.execute("boot", option);
    }

    this.isShow = true;
  },
  hide() {
    this.execute("hide");
    this.execute("shutdown");

    this.isShow = false;
  },
  open() {
    this.execute("show");
    this.execute("lounge");
  },
};

const plugin = {
  install: (app: App, options: any = {}) => {
    if (config.mode !== "production") {
      return;
    }

    loadScript(
      "https://cdn.channel.io/plugin/ch-plugin-web.js",
      (error, script) => {
        if (error) {
          // console.error(error);
          return;
        }

        const w: any = window;
        const ch: any = (...args) => {
          ch.c(...args);
        };
        ch.q = [];
        ch.c = (args: any) => {
          ch.q.push(args);
        };
        w.ChannelIO = ch;
        w.ChannelIOInitialized = true;

        setTimeout(() => {
          ChannelTalk.init();
        }, 0);
      },
    );

    app.config.globalProperties.$ch = ChannelTalk;
  },
};

export default plugin;
