<template>
  <div class="animation lottie">
    <div
      v-show="animating"
      ref="animation"
      class="lottie-container"
      :style="style"
    ></div>
    <div v-show="!animating" class="lottie-still">
      <slot ref="still" name="still"></slot>
    </div>
  </div>
</template>

<script>
import lottie from "lottie-web";

export default {
  name: "VueLottie",
  props: {
    animationData: {
      type: Object,
      required: true,
    },
    loop: {
      type: Boolean,
      default: true,
    },
    autoplay: {
      type: Boolean,
      default: true,
    },
    animating: {
      type: Boolean,
      default: true,
    },
    width: {
      type: [Number, String],
    },
    height: {
      type: [Number, String],
    },
  },

  computed: {
    style() {
      return {
        width: this.width ? `${this.width}px` : "100%",
        height: this.height ? `${this.height}px` : "100%",
        // overflow: "hidden",
        // margin: "0 auto",
      };
    },
    // rendererSettings() {
    //   let canvasContext = this.$refs.canvas.getContext("2d")
    //   return {
    //     context: canvasContext, // the canvas context
    //     // preserveAspectRatio: 'xMinYMin slice', // Supports the same options as the svg element's preserveAspectRatio property
    //     clearCanvas: false,
    //     progressiveLoad: true, // Boolean, only svg renderer, loads dom elements when needed. Might speed up initialization for large number of elements.
    //     hideOnTransparent: true, //Boolean, only svg renderer, hides elements when opacity reaches 0 (defaults to true)
    //   }
    // }
  },
  watch: {
    animating(animating) {
      if (this.lottie) {
        if (animating) {
          this.lottie.play();
        } else {
          this.lottie.stop();
        }
      }
    },
  },
  mounted() {
    this.lottie = lottie.loadAnimation({
      name: `lottie-${+new Date()}`,
      container: this.$refs.animation,
      renderer: "svg",
      loop: this.loop !== false,
      autoplay: this.autoplay !== false,
      animationData: this.animationData,
      // rendererSettings: this.rendererSettings
    });

    this.onComplete = this.onComplete.bind(this);
    this.lottie.addEventListener("complete", this.onComplete);
  },
  beforeUnmount() {
    if (this.lottie) {
      this.lottie.removeEventListener("complete", this.onComplete);

      this.lottie.destroy();
      this.lottie = null;
    }
  },
  methods: {
    onComplete() {},
    onSpeed(value = 1) {
      this.lottie.setSpeed(value);
    },
  },
};
</script>
