import Toaster from "./Toaster.vue";
import eventBus from "./helpers/event-bus";
import mount from "./helpers/mount-component";

interface Options {
  type: string;
}

const defaultOptions = {
  type: "",
};

const API = (globalOptions = {}) => ({
  show(message: string, options: any = {}) {
    const localOptions: any = { message, ...options };

    const mountOptinos: any = {
      props: { ...globalOptions, ...localOptions },
    };

    if (mountOptinos.props?.maxToasts === 1) {
      this.clear();
    }

    const c = mount(Toaster, mountOptinos);

    return c;
  },
  clear() {
    eventBus.$emit("toast-clear");
  },
  success(message: string, options: Options = defaultOptions) {
    options.type = "success";
    return this.show(message, options);
  },
  error(message: string, options: Options = defaultOptions) {
    options.type = "error";
    return this.show(message, options);
  },
  info(message: string, options: Options = defaultOptions) {
    options.type = "info";
    return this.show(message, options);
  },
  warning(message: string, options: Options = defaultOptions) {
    options.type = "warning";
    return this.show(message, options);
  },
});

export default API;
