import { Module, ActionContext, GetterTree } from "vuex";
import { State } from "../typings";

export class LayerState {
  public items: any = [];
}

const getters: GetterTree<LayerState, State> = {
  GET_ITEMS: (state) => state.items,
};

export const dialog: Module<LayerState, State> = {
  namespaced: true,
  state: new LayerState(),
  mutations: {
    ADD_ITEM(state, payload) {
      state.items = [];
      setTimeout(() => {
        state.items.push(payload);
      }, 0);
    },
    REMOVE_ITEM(state, payload) {
      state.items = state.items.filter((o: any) => o !== payload);
    },
  },
  getters,
};

export default dialog;
