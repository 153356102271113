import _, { flatten } from "lodash";
import language from "@/plugins/language";
import dayjs from "@/plugins/dayjs";

const stringToHex = (str: string) => {
  const arr: any[] = [];
  for (let i = 0; i < str.length; i += 1) {
    arr[i] = `00${str.charCodeAt(i).toString(16)}`.slice(-4);
  }
  return `\\u${arr.join("\\u")}`;
};

export const isTouchDevice = (): boolean => {
  let hasTouchScreen = false;
  if ("maxTouchPoints" in window.navigator) {
    hasTouchScreen = window.navigator.maxTouchPoints > 0;
  } else if ("msMaxTouchPoints" in navigator) {
    hasTouchScreen = window.navigator.maxTouchPoints > 0;
  } else {
    const mQ =
      typeof window.matchMedia === "function" && matchMedia("(pointer:coarse)");
    if (mQ && mQ.media === "(pointer:coarse)") {
      hasTouchScreen = !!mQ.matches;
    } else if ("orientation" in window) {
      hasTouchScreen = true; // deprecated, but good fallback
    } else {
      // Only as a last resort, fall back to user agent sniffing
      const UA = window.navigator.userAgent;
      hasTouchScreen =
        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
        /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
    }
  }

  return hasTouchScreen;
};

export const localeTicketTime = ({
  ticket_date_start,
  ticket_date_end,
}: {
  ticket_date_start: string;
  ticket_date_end: string;
}): string => {
  let value = "";

  const dateStartString = ticket_date_start
    ? dayjs(ticket_date_start).format(language.translate("MM월 DD일 HH:mm"))
    : "";
  const dateEndString = ticket_date_end
    ? dayjs(ticket_date_end).format(language.translate("MM월 DD일 HH:mm"))
    : "";

  value = `${dateStartString} ~ ${dateEndString}`;

  return value;
};

export const localeTicketQuantity = ({
  ticket_quantity,
  ticket_over_quantity,
}: {
  ticket_quantity: number;
  ticket_over_quantity: number;
}): string => {
  let value = "";

  if (ticket_quantity > 0) {
    const quantityString = `${language.translate(
      "{luggage-selector}.small",
    )} ${ticket_quantity}`;

    if (ticket_quantity > 1) {
      value += quantityString + language.translate("item");
    } else {
      value += quantityString + language.translate("items");
    }
  }

  if (ticket_over_quantity > 0) {
    let quantityString = `${language.translate(
      "{luggage-selector}.big",
    )} ${ticket_over_quantity}`;

    if (ticket_quantity > 0) {
      quantityString = ` ${quantityString}`;
    }

    if (ticket_over_quantity > 1) {
      value += quantityString + language.translate("item");
    } else {
      value += quantityString + language.translate("items");
    }
  }

  return value;
};

export const isEmpty = (value: any) => {
  if (value === null) {
    return true;
  }
  if (typeof value === "undefined") {
    return true;
  }
  if (typeof value === "string" && value === "") {
    return true;
  }
  if (Array.isArray(value) && value.length === 0) {
    return true;
  }
  if (
    typeof value === "object" &&
    value.constructor.name === "Object" &&
    Object.keys(value).length < 1 &&
    Object.getOwnPropertyNames(value).length < 1
  ) {
    if (
      typeof value === "object" &&
      value.constructor.name === "String" &&
      Object.keys(value).length < 1
    ) {
      return true;
    }
  }

  return false;
};

export const removeEmpty = (obj: any) =>
  Object.entries(obj)
    .filter(([_, v]) => v != null)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

export const getLoadedScript = (url: string): boolean => {
  const scripts = window.document.getElementsByTagName("script");
  let value: any = null;

  for (let i = 0; i < scripts.length; i += 1) {
    if ((scripts[i].src ?? "").includes(url)) {
      value = scripts[i];
      break;
    }
  }

  return value;
};

export const getDefaultBookDates = (): {
  date_start: string;
  date_end: string;
} => {
  const currentDay = dayjs().add(10, "m");
  const remainMinutes = 10 - (dayjs(currentDay).minute() % 10);

  const dateStartDay = currentDay.add(remainMinutes, "minutes");
  const dateEndDay = dateStartDay.add(10, "minutes");

  return {
    date_start: dateStartDay.format("YYYY-MM-DD HH:mm"),
    date_end: dateEndDay.format("YYYY-MM-DD HH:mm"),
  };
};

export const getUrlFromResources = (resources: any) => {
  if (isEmpty(resources)) {
    return resources;
  }

  if (typeof resources === "object") {
    return flatten(Object.values(resources))
      .map((o: any) => o.resource_url)
      .filter((o) => o!!);
  }

  return [];
};

export const isDescendant = (el: HTMLElement, parentId: string) => {
  let isChild = false;
  if (el.id === parentId) {
    isChild = true;
  }

  while (el === el.parentNode) {
    if (el.id === parentId) {
      isChild = true;
    }
  }

  return isChild;
};

export const getDateLabel = (dateTime: any) => {
  const dayjsDateTime = dayjs(dateTime);

  const weekDays = [
    language.translate("SSA_SUN"),
    language.translate("SSA_MON"),
    language.translate("SSA_TUE"),
    language.translate("SSA_WED"),
    language.translate("SSA_THU"),
    language.translate("SSA_FRI"),
    language.translate("SSA_SAT"),
  ];

  let formattedDate = dayjsDateTime.format("MM/DD");
  const day = dayjsDateTime.day();

  if (dayjsDateTime.isSame(new Date(), "day")) {
    formattedDate = language.translate("{date-time-picker}.today");
  } else {
    formattedDate += ` (${weekDays[day]})`;
  }

  return formattedDate;
};

export const sortForKey = (
  items: Array<any>,
  key: any,
  order: string = "DESC",
) =>
  items.sort((a, b) => {
    if (order === "ASC") {
      return +(a[key] < b[key]) || +(a[key] === b[key]) - 1;
    }

    return +(a[key] > b[key]) || +(a[key] === b[key]) - 1;
  });

export function createContextInterval(
  this: any,
  key: any,
  callback: Function,
  duration: number = 0,
) {
  const context: any = this;
  if (!context) {
    return;
  }

  if (!context.__UHOO_INTERVAL__) {
    context.__UHOO_INTERVAL__ = {};
  }

  context.__UHOO_INTERVAL__[key] = setInterval(callback, duration);
}

export function removeContextInterval(this: any, key: any) {
  const context: any = this;

  if (!context?.__UHOO_INTERVAL__) {
    return;
  }

  if (context.__UHOO_INTERVAL__[key]) {
    clearInterval(context.__UHOO_INTERVAL__[key]);
    context.__UHOO_INTERVAL__[key] = null;
  }
}

export function wait(ms) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function generateQueryString(options: any) {
  let queryString = "?";

  Object.keys(options).forEach((o) => {
    if (options[o] || options[o] === 0) {
      queryString += `${o}=${options[o]}&`;
    }
  });

  return queryString;
}

export function debounce(cb, duration) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      cb(...args);
    }, duration);
  };
}

export function parseJSON(jsonString, defaultValue = {}) {
  if (
    jsonString === "null" ||
    jsonString === null ||
    typeof jsonString === "undefined"
  ) {
    return defaultValue;
  }

  let result = jsonString && _.isObject(jsonString) ? jsonString : defaultValue;

  if (_.isString(jsonString)) {
    try {
      result = JSON.parse(jsonString || "{}");
    } catch (e) {
      // console.error("parseJSON Error: ", e, jsonString);
      result = defaultValue;
    }
  }
  return result;
}
