<template>
  <div class="backward-header">
    <div class="backward-header__container">
      <ui-button
        class="backward-header__button backward-header__button--left"
        touchEffect
        @click="$emit('close')"
      >
        <svg
          width="48"
          height="48"
          viewBox="-12 -12 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 5L19 19"
            stroke="#292A2B"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5 19L19 5"
            stroke="#292A2B"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </ui-button>
      <div class="title">{{}}</div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
// import { openSidebar, setSidebar } from "@/composable/layout";

export default {
  emits: ["close"],
  props: {
    sidebar: Boolean,
  },
  setup() {
    const router = useRouter();

    const actions = {
      onBack: () => {
        router.back();
      },
    };
    return {
      ...actions,
    };
  },
};
</script>

<style lang="scss" scoped>
.backward-header {
  height: unit(5.6);
  background: $color-white;

  &__container {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: unit(0.8);
    height: 100%ƒ;
    max-width: $max-content-width;
    margin: auto;
  }

  &__button {
    flex: 0 0 unit(5.6);
    padding: 0 !important;
    border-radius: unit(3);

    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__title {
    flex: 1;
    font-size: unit(1.4);
  }

  &.primary {
    background: $color-primary;

    svg {
      path {
        stroke: $color-white;
      }
    }
  }

  &.blurred {
    background: linear-gradient(
      0,
      rgba(0, 0, 0, 0.1) 10%,
      rgba(0, 0, 0, 0.5) 80%
    );

    svg {
      path {
        stroke: $color-white;
      }
    }
  }
}
</style>
