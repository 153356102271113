import { computed } from "@vue/reactivity";

import store from "@/store";
import router from "@/router";

export const profile = computed(() => store.getters["auth/profile"]);
export const isLoggedIn = computed(() => !!profile.value?.account);

export const verifyAuth = () => {
  return store.dispatch("auth/verify");
};

export const updateAuth = (options: any): Promise<any> => {
  return store.dispatch("auth/update", options);
};

export const requestSendLoginAuthCode = (options: any): Promise<any> => {
  return store.dispatch("auth/requestLoginCode", options);
};

export const requestSendRegisterAuthCode = (options: any): Promise<any> => {
  return store.dispatch("auth/requestRegisterCode", options);
};

export const requestConfirmLoginAuthCode = async (
  options: any,
): Promise<any> => {
  const data = await store.dispatch("auth/confirmCode", {
    ...options,
    auth_type: "login",
  });
  if (!isLoggedIn.value) {
    throw new Error("INVALID_CODE");
  }

  return data;
};

export const requestConfirmRegisterAuthCode = async (
  options: any,
): Promise<any> => {
  const data = await store.dispatch("auth/confirmCode", {
    ...options,
    auth_type: "register",
  });
  if (!isLoggedIn.value) {
    throw new Error("INVALID_CODE");
  }

  return data;
};

export const logout = ({ redirect }: { redirect: string }) => {
  store.dispatch("auth/logout");
  router.push(redirect);
};

export default {
  isLoggedIn,
  profile,
};
