<template>
  <teleport to="#teleport-area">
    <div class="dialog-container">
      <transition-group tag="ul" name="dialog">
        <dialog-item v-for="(item, i) in items" :key="i" :data="item" />
      </transition-group>
    </div>
  </teleport>
</template>

<script>
import DialogItem from "./Item";

export default {
  name: "DialogBody",
  components: {
    DialogItem,
  },
  computed: {
    items() {
      return this.$store.getters["dialog/GET_ITEMS"];
    },
  },
};
</script>

<style lang="scss"></style>
