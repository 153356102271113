import { computed } from "@vue/reactivity";
import store from "@/store";
import MobidicMap from "@/plugins/mobidic-map";

type fetchSpaceOptions = {
  latitude?: string | number;
  longitude?: string | number;
  radius?: string | number;
  list?: string;
  [propName: string]: any;
};
export function getSpace({ id }) {
  return store.getters["spaces/getItem"](id);
}

export function fetchSpace({ id }) {
  return store.dispatch("spaces/fetchOne", { id });
}

export function getSpaceList({ list }) {
  return store.getters["spaces/getList"]({ list });
}

export async function fetchSpaceList(options: fetchSpaceOptions) {
  const data = await store.dispatch("spaces/searchAround", {
    ...options,
    clearGroup: true,
  });

  return data;
}

export function createMarkerOptions({ id, activated, latitude, longitude }) {
  const markerUrl = activated
    ? "/img/markers/map-marker-uhoo-activated.svg"
    : "/img/markers/map-marker-uhoo.svg";

  return {
    id,
    markerUrl,
    latitude,
    longitude,
    zIndex: activated ? 2 : 1,
  };
}

export async function searchLocation(keyword) {
  const { items } = await store.dispatch("spaces/searchLocation", { keyword });

  if (items.length > 0) {
    return items[0];
  }

  throw new Error("NO_EXISTS_DATA");
}

export async function inquireSpace(options: any) {
  const data = await store.dispatch("spaces/inquire", options);
  return data;
}

export function sortSpaceListByDistance(
  list: any[],
  point: { latitude: any; longitude: any },
  order = "DESC",
) {
  const pointOfTarget = {
    latitude: point.latitude,
    longitude: point.longitude,
  };
  const mobidicMap = MobidicMap.defaultInstance();

  list.sort((a, b) => {
    const pointOfA = { latitude: a?.latitude, longitude: a?.longitude };
    const pointOfB = { latitude: b?.latitude, longitude: b?.longitude };

    const distanceOfA = mobidicMap.getDistanceBetween(pointOfA, pointOfTarget);
    const distanceOfB = mobidicMap.getDistanceBetween(pointOfB, pointOfTarget);

    let value = 0;

    if (order === "DESC") {
      value = distanceOfA - distanceOfB;
    }
    if (order === "ASC") {
      value = distanceOfB - distanceOfA;
    }

    return value;
  });

  return list;
}

export default {
  getSpace,
  getSpaceList,
  fetchSpaceList,
  createMarkerOptions,
};
