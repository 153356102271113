<template>
  <li class="ui-form-list-item">
    <slot />
  </li>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.ui-form-list-item {
  & + .ui-form-list-item {
    margin-top: unit(1.2);
  }
}
</style>
