<template>
  <form
    ref="form"
    spellcheck="false"
    autocomplete="off"
    @submit.prevent="onSubmit"
  >
    <slot />
  </form>
</template>

<script>
import { isRef } from "@vue/reactivity";

export default {
  name: "UiForm",
  props: ["formData", "ignoreSubmit", "autocomplete"],
  emits: ["submit", "error"],
  data() {
    return {
      existError: false,
    };
  },
  methods: {
    onSubmit() {
      // this.$log.log("onSubmit()")
      if (this.ignoreSubmit === true) {
        return false;
      }

      this.submitForm();
      return true;
    },

    submit() {
      // this.$log.log("submit()")
      this.submitForm();
    },
    submitForm() {
      this.existError = false;
      Object.keys(this.formData).forEach((o) => {
        this.validateField(
          isRef(this.formData[o]) ? this.formData[o].value : this.formData[o],
        );
      });

      if (!this.existError) {
        this.$emit("submit", this.serializeFields());
      } else {
        this.$emit("error");
      }
    },
    validateField(ob) {
      if (ob.value && typeof ob.value === "string") {
        ob.value = ob.value.trim();
      }
      if (!ob.validation) {
        return;
      }
      // this.$log.log(JSON.stringify(ob));

      if (!Array.isArray(ob.validation)) {
        ob.validation = [{ ...ob.validation }];
      }

      ob.validation.forEach((o) => {
        let regExp = null;
        let result = null;
        if (o.type.split(":").length > 1) {
          const vn = o.type.split(":")[0];
          const vv = o.type.split(":")[1];
          switch (vn) {
            case "min":
              if (ob.value.length < vv) {
                result = o.failMessage
                  ? o.failMessage
                  : this.$__t("__VALIDATION_ERROR_MIN_PRE") +
                    vv +
                    this.$__t("__VALIDATION_ERROR_MIN_SUF");
              }
              break;
            case "max":
              if (ob.value.length > vv) {
                result = o.failMessage
                  ? o.failMessage
                  : this.$__t("__VALIDATION_ERROR_MAX_PRE") +
                    vv +
                    this.$__t("__VALIDATION_ERROR_MAX_SUF");
              }
              break;
            case "matchField":
              if (ob.value !== this.formData[vv].value) {
                result = o.failMessage
                  ? o.failMessage
                  : this.$__t("Password are not matched.");
              }
              break;
            case "matchString":
              if (ob.value !== vv) {
                result = o.failMessage
                  ? o.failMessage
                  : this.$__t("__VALIDATION_ERROR_MATCH_STRING_PRE") +
                    vv +
                    this.$__t("__VALIDATION_ERROR_MATCH_STRING_SUF");
              }
              break;
            default:
              break;
          }
        } else {
          const vn = o.type;
          // this.$log.log("type:", vn, ob);
          switch (vn) {
            case "password":
              regExp = /^[0-9]{4}$/;
              if (typeof ob.value !== "string" || !regExp.test(ob.value)) {
                result = o.failMessage
                  ? o.failMessage
                  : this.$__t("Password must be numbers of 4.");
              }
              break;
            case "email":
              regExp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
              if (!regExp.test(ob.value)) {
                result = o.failMessage
                  ? o.failMessage
                  : this.$__t("__VALIDATION_ERROR_EMAIL");
              }
              break;
            case "required":
              if (typeof ob.value !== "number" && !ob.value) {
                result = o.failMessage
                  ? o.failMessage
                  : this.$__t("__VALIDATION_ERROR_REQUIRED");
              }
              break;
            case "number":
              regExp = /^[0-9]+$/;
              if (!regExp.test(ob.value)) {
                result = o.failMessage
                  ? o.failMessage
                  : this.$__t("__VALIDATION_ERROR_NUMBER");
              }
              break;
            case "english_first":
              regExp = /^[A-Za-z ]*$/;
              if (!ob.value || !regExp.test(ob.value)) {
                result = o.failMessage
                  ? o.failMessage
                  : this.$__t("English first name on passport.");
              }
              break;
            case "english_last":
              regExp = /^[A-Za-z ]*$/;
              if (!ob.value || !regExp.test(ob.value)) {
                result = o.failMessage
                  ? o.failMessage
                  : this.$__t("English last name on passport.");
              }
              break;
            case "koreanOrEnglish":
              regExp = /^[가-힣|A-Za-z]*$/;
              if (!ob.value || !regExp.test(ob.value)) {
                result = o.failMessage
                  ? o.failMessage
                  : this.$__t("Please insert Korean or English");
              }
              break;
            case "passwordLength":
              regExp = /^(?=.{8,20}$).*$/;
              if (!ob.value || !regExp.test(ob.value)) {
                result = o.failMessage
                  ? o.failMessage
                  : this.$__t(
                      "Your password must be between 8 and 20 characters.",
                    );
              }
              break;
            case "length":
              regExp = /^(?=.{4}$).*$/;
              if (!regExp.test(ob.value)) {
                if (o.length === 4) {
                  result = o.failMessage
                    ? o.failMessage
                    : this.$__t("The password is four digits.");
                }
              }
              break;
            default:
              break;
          }
        }

        if (result) {
          if (ob.error !== this.$__t("__VALIDATION_ERROR_REQUIRED")) {
            ob.error = result;
            console.log(ob.error);
          }
          this.existError = true;
        } else {
          ob.error = "";
        }
      });
    },
    serializeFields() {
      const values = {};
      Object.keys(this.formData).forEach((o) => {
        if (
          Array.isArray(this.formData[o].value) ||
          typeof this.formData[o].value === "object"
        ) {
          values[o] = JSON.stringify(this.formData[o].value);
        } else if (typeof this.formData[o].value === "boolean") {
          values[o] = this.formData[o].value;
        } else {
          values[o] = this.formData[o].value.toString();
        }
      });
      return values;
    },
    resetForm() {
      Object.keys(this.formData).forEach((o) => {
        // eslint-disable-next-line vue/no-mutating-props
        this.formData[o].value = "";
      });
    },
  },
};
</script>
