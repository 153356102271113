import checkMobile from "is-mobile";

export const isMobile = checkMobile({ tablet: true });

export const getCurrentLocation = (
  onSuccess: any,
  onError: any,
  { timeout, maximumAge, enableHighAccuracy }: any,
) => {
  const options = {
    timeout: timeout ?? 5000,
    maximumAge: maximumAge ?? 10 * 1000,
    enableHighAccuracy: enableHighAccuracy ?? false,
  };

  navigator.geolocation.getCurrentPosition(onSuccess, onError, options);
};

export default {
  isMobile,
  getCurrentLocation,
};
