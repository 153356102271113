<template>
  <teleport to="#teleport-area">
    <div class="alert-container">
      <transition-group tag="ul" name="alert">
        <alert-item
          v-for="(item, i) in items"
          :key="i + '-' + item.status"
          :data="item"
        ></alert-item>
      </transition-group>
    </div>
  </teleport>
</template>

<script>
import AlertItem from "./Item";

export default {
  name: "AlertBody",
  components: {
    AlertItem,
  },
  computed: {
    items() {
      return this.$store.getters["alert/GET_ITEMS"];
    },
  },
};
</script>

<style lang="scss">
.alert-item {
  @include fsf;
  top: auto;
  max-width: $site-width-g;
  width: 100%;
  margin: unit(2.4) auto;
  z-index: 1700;
  color: $color-white;

  & > .container {
    margin: 0 unit(2.4);
    @include shadow-box($color-shadow);
  }

  .alert-body {
    padding: unit(1.2) unit(3.6) unit(1.2) unit(4.8);
  }

  .close {
    @include fsa;
    right: auto;
    width: unit(4.8);
    height: unit(4.8);
    font-size: unit(2.2);
    line-height: unit(4.8);
    text-align: center;
    cursor: pointer;
    z-index: 1;
  }

  & {
    & > .container {
      background-color: $color-black;
    }
  }

  &.error {
    & > .container {
      background-color: $color-red;
    }
  }

  &.alert {
    & > .container {
      background-color: $color-secondary;
    }
  }

  &.success {
    & > .container {
      background-color: $color-primary;
    }
  }
}
</style>
