<template>
  <li class="slider-item" :class="sliderClass">
    <div class="container" @click.stop="">
      <component
        :is="data.component"
        v-if="data.component"
        :data="data.data"
        @close="removeItem"
      />
    </div>
  </li>
</template>

<script>
import SpaceSlider from "@/components/resources/spaces/SpaceSlider.vue";

export default {
  name: "SliderItem",
  components: {
    SpaceSlider,
  },
  props: ["data"],
  computed: {
    sliderClass() {
      const { transparent } = this.data.options;

      return {
        transparent,
      };
    },
  },

  mounted() {
    this.$watch("$route", (a, b) => {
      this.removeItem();
    });
    this.$el.addEventListener("mousedown", (e) => {
      if (this.$store.getters.IS_TOUCH_DEVICE || e.which === 1) {
        if (e.target === this.$el) {
          this.removeItem();
        }
      }
    });
  },
  methods: {
    removeItem() {
      if (typeof this.data.onClose === "function") {
        this.data.onClose(this);
      }

      this.$store.commit("slider/REMOVE_ITEM", this.data);
    },
  },
};
</script>
