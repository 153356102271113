<template>
  <div class="resource space-card">
    <div class="container">
      <div class="space-card__basic">
        <div class="frame">
          <img :src="thumbnail" />
        </div>
        <div class="textarea">
          <span class="category"> {{ category }} </span>
          <h2 class="main">상담 시 조회 가능</h2>
          <div class="space-address">
            <p class="space-address__text">
              {{ space?.name?.ko || space?.name?.en || space?.name }}
            </p>
            <p class="space-address__text">
              {{ space?.address?.ko || space?.address?.base }}
            </p>
          </div>
        </div>
      </div>
      <!-- <ul class="space-card__facility-list">
        <li v-for="item in facilityItems" :key="item" class="facility-item">
          <span class="facility-item__text">{{ item }}</span>
        </li>
      </ul> -->
    </div>
  </div>
</template>

<script>
import { onBeforeMount, onMounted } from "vue";

import { computed, ref, toRefs } from "@vue/reactivity";
import { getSpace } from "@/composable/spaces";

const defaultImage = require("@/assets/images/defaults/no-image.png");

export default {
  props: ["id", "activated"],

  setup(props, { emit }) {
    const { id } = toRefs(props);
    const space = computed(() => getSpace({ id: id.value }));
    const facilityItems = computed(() => {
      const value = ["물품보관", "도심 물류 공간"];

      return value;
    });

    const thumbnail = computed(() => {
      return space.value?.resources?.thumbnail_image || defaultImage;
    });

    const category = computed(() => {
      let value = "실내";

      const floor = space.value?.floor;

      if (floor) {
        const isUnderground = floor < 0;
        value += ` · ${isUnderground ? Math.abs(floor) : floor}층`;

        return value;
      }

      return value;
    });

    return {
      space,
      facilityItems,
      thumbnail,
      category,
    };
  },
};
</script>

<style lang="scss" scoped>
.space-card {
  height: unit(17);
  padding: unit(2) unit(1.6);

  &__basic {
    display: flex;
    gap: unit(1.8);

    .frame {
      width: unit(10);
      height: unit(9);
      flex: 0 0 unit(10);
      border-radius: unit(2);

      img {
        border-radius: unit(2);
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .textarea {
      .category {
        font-weight: 400;
        font-size: unit(1.4);
        color: $color-black;
      }

      .main {
        font-weight: bold;
        font-size: unit(1.8);
        color: $color-black;
      }

      .space-address {
        margin-top: unit(0.8);

        &__text {
          font-size: unit(1.2);
          line-height: 1.45;
          color: #61676c;
        }
      }
    }
  }

  &__facility-list {
    margin-top: unit(1.2);
    display: flex;
    gap: unit(0.6);

    .facility-item {
      display: inline-block;
      background: #f1f5f5;
      border-radius: unit(10);
      padding: unit(0.4) unit(1);

      &__text {
        font-weight: 400;
        font-size: unit(1.2);
        line-height: 1.5;
      }
    }
  }
}
</style>
