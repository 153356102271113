import { Module, GetterTree, MutationTree, ActionTree, Mutation } from "vuex";
import { State } from "../typings";

import config from "@/config";
import network from "@/plugins/network";

const { adminKey } = config.get("uhoo");

export class SpaceState {
  public items: any = {};

  public lists: any = {};
}

const getters: GetterTree<SpaceState, State> = {
  getItem: (state, getters) => (key) => {
    return state.items[key];
  },
  getItemWith: (state, getters) => (payload: any) => {
    let filteredItem = null;
    if (payload && payload.key && payload.value) {
      Object.keys(state.items).forEach((o) => {
        if (state.items[o][payload.key] === payload.value) {
          filteredItem = state.items[o];
        }
      });
    }
    return filteredItem;
  },
  getItems: (state, getters) => (payload: any) => {
    // payload : { key, value }
    const filteredItems: any = [];
    if (payload && payload.key && payload.value) {
      Object.keys(state.items).forEach((o) => {
        if (state.items[o][payload.key] === payload.value) {
          filteredItems.push(state.items[o]);
        }

        if (
          Array.isArray(payload.value) &&
          payload.value.includes(state.items[o][payload.key])
        ) {
          filteredItems.push(state.items[o]);
        }
      });
    }

    return filteredItems;
  },
  getList: (state, getters) => (payload: any) => {
    if (payload && payload.list && state.lists[payload.list]) {
      return state.lists[payload.list];
    }

    return [];
  },
  getItemsByList: (state, getters) => (payload: any) => {
    // payload : { list }
    let filteredItems = [];
    if (payload && payload.list && state.lists[payload.list]) {
      filteredItems = state.lists[payload.list].map((o: any) => state.items[o]);
    }

    return filteredItems;
  },
};

const mutations: MutationTree<SpaceState> = {
  SET_ITEM(state, payload) {
    if (typeof payload.data === "object" && payload.data.uid) {
      if (state.items[payload.data.uid]) {
        state.items[payload.data.uid] = {
          ...state.items[payload.data.uid],
          ...payload.data,
        };
      } else {
        state.items[payload.data.uid] = { ...payload.data };
      }
    }
  },
  SET_ITEMS(state, payload) {
    if (typeof payload.data === "object" && payload.data.length) {
      payload.data.forEach((item: any) => {
        if (state.items[item.uid]) {
          state.items[item.uid] = { ...state.items[item.uid], ...item };
        } else {
          state.items[item.uid] = { ...item };
        }
      });
    }
  },
  UNSET_ITEM(state, payload) {
    if (payload.data.uid) {
      Object.keys(state.lists).forEach((o) => {
        state.lists[o] = state.lists[o].filter(
          (o: any) => o !== payload.data.uid,
        );
      });
      delete state.items[payload.data.uid];
    }
  },
  SET_LIST(state, payload) {
    if (payload.list) {
      state.lists[payload.list] = [];
    }
  },
  SET_LIST_ITEM(state, payload) {
    if (
      payload.list &&
      state.lists[payload.list] &&
      typeof payload.data === "object" &&
      payload.data.uid
    ) {
      if (state.lists[payload.list].indexOf(payload.data.uid) < 0) {
        state.lists[payload.list].unshift(payload.data.uid);
      }
    }
  },
  SET_LIST_ITEMS(state, payload) {
    if (
      payload.list &&
      state.lists[payload.list] &&
      typeof payload.data === "object" &&
      payload.data.length
    ) {
      payload.data.forEach((o: any) => {
        if (state.lists[payload.list].indexOf(o.uid) < 0) {
          state.lists[payload.list].push(o.uid);
        }
      });
    }
  },

  CLEAR_LIST(state, payload) {
    if (payload.list && state.lists[payload.list]) {
      state.lists[payload.list] = [];
    }
  },
  CLEAR_LISTS(state) {
    state.lists = {};
  },
  CLEAR_ITEMS(state) {
    state.items = {};
  },
};

const actions: ActionTree<SpaceState, State> = {
  async fetchOne({ state, rootState, commit, dispatch }, payload) {
    const data = await network.get(`v1/spaces/${payload.id}`, payload, {
      headers: {
        AdminKey: adminKey,
      },
    });

    commit("SET_ITEM", { data });
    if (payload.list) {
      if (!state.lists[payload.list]) {
        commit("SET_LIST", { list: payload.list });
      }
      commit("SET_LIST_ITEM", { list: payload.list, data });
    }

    return data;
  },

  async searchAround({ state, rootState, commit, dispatch }, payload) {
    const data = await network.get(`v1/spaces/uhoo/search/around`, payload, {
      ...(payload.options || {}),
      clearGroup: true,
    });
    commit("SET_ITEMS", { data: data.items });
    if (payload.list) {
      if (!state.lists[payload.list]) {
        commit("SET_LIST", { list: payload.list });
      }
      commit("SET_LIST_ITEMS", { list: payload.list, data: data.items });
    }

    return data;
  },

  async searchLocation({ state, rootState, commit, dispatch }, payload) {
    const data = await network.get(`v1/spaces/geocode`, payload, {
      headers: {
        AdminKey: adminKey,
      },
    });

    return data;
  },

  async inquire({ state, rootState, commit, dispatch }, payload) {
    const data = await network.post(
      `v1/spaces/${payload.id}/acquisition`,
      payload,
    );

    return data;
  },

  clear({ state, rootState, commit, dispatch }, payload) {
    commit("CLEAR_LISTS");
    commit("CLEAR_ITEMS");
  },
};

const store: Module<SpaceState, State> = {
  namespaced: true,
  state: new SpaceState(),
  getters,
  mutations,
  actions,
};

export default store;
