import { Module, ActionContext, GetterTree } from "vuex";
import { State } from "../typings";

export class LayerState {
  public items: any = {};
}

const getters: GetterTree<LayerState, State> = {
  GET_ITEMS: (state) => state.items,
};

export const modal: Module<LayerState, State> = {
  namespaced: true,
  state: new LayerState(),
  mutations: {
    ADD_ITEM(state, payload) {
      state.items[payload.component] = payload;
    },
    REMOVE_ITEM(state, payload) {
      if (state.items[payload.component]) {
        delete state.items[payload.component];
      }
    },
  },
  getters,
};

export default modal;
