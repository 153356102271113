import { App, Plugin } from "vue";
import Toaster from "./Toaster.vue";
import API from "./api";
import Positions from "./defaults/positions";

const install = (app: App, options: any = {}) => {
  const methods = API(options);
  app.config.globalProperties.$toast = methods;
  app.provide("$toast", methods);
};

const ToastPlugin: Plugin = {
  install,
};

export default ToastPlugin;
export { ToastPlugin, Positions };
