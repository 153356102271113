<template>
  <teleport to="#teleport-area">
    <div class="lug-modal">
      <transition-group tag="ul" name="modal">
        <ModalItem
          v-for="(value, name) in items"
          :key="`modal-${name}`"
          :data="value"
        ></ModalItem>
      </transition-group>
    </div>
  </teleport>
</template>

<script>
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import ModalItem from "./Item.vue";

export default {
  name: "ModalBody",
  setup(props, { attrs, slots, emit, expose }) {
    const store = useStore();

    const items = computed(() => store.getters["modal/GET_ITEMS"]);

    return {
      items,
    };
  },
  components: {
    ModalItem,
  },
};
</script>

<style lang="scss">
.modal-item {
  @include fsf;
  padding: unit(3);
  background-color: $color-shadow;
  z-index: 1500;

  &.big {
    @include vertical-center-container("& > .container", $site-width-f);
  }

  &.small {
    @include vertical-center-container("& > .container", $site-width-h);
  }

  & > .container {
    @include shadow-box($color-shadow);
  }

  .close {
    @include fsa;
    bottom: auto;
    left: auto;
    width: unit(4.8);
    height: unit(4.8);
    font-size: $font-size-large;
    line-height: unit(4.8);
    text-align: center;
    cursor: pointer;
    z-index: 1;
  }
}
</style>
