<template>
  <div class="resource user-terms-agree">
    <div class="header">
      <h1 class="header__text">회원가입</h1>
    </div>

    <div class="content">
      <h2 class="form-title">약관동의</h2>

      <user-terms-agree-form class="user-terms-form" @agree="onAgree" />
    </div>
  </div>
</template>

<script>
import {
  createAlert,
  createModal,
  ModalSize,
  removeModal,
} from "@/composable/layout";
import UserTermsAgreeForm from "./UserTermsAgreeForm.vue";

export default {
  name: "UserTermsAgreeModal",
  components: { UserTermsAgreeForm },

  setup() {
    const onAgree = ({ term_1, term_2, optional_ad }) => {
      createModal({
        component: "UserRegisterModal",
        size: ModalSize.BIG,
        formData: { term_1, term_2, optional_ad },
      });
      removeModal({ component: "UserTermsAgreeModal" });
    };

    return {
      onAgree,
    };
  },
};
</script>

<style lang="scss" scoped>
.user-terms-agree {
  .header {
    &__text {
      font-weight: 700;
      font-size: unit(2);
    }

    &__description {
      margin-top: unit(0.6);
      font-weight: 400;
      font-size: unit(1.4);
    }
  }

  .content {
    .user-terms-form {
      margin-top: unit(3);
    }
    .submit-area {
      margin-top: unit(2.4);
    }
  }

  .footer {
    margin-top: unit(12);
    text-align: center;

    .text {
      font-weight: bold;
      font-size: unit(1.2);
      color: #61676c;

      &--emph {
        font-size: unit(1.2) !important;
        font-weight: bold !important;
        text-decoration: underline;
        cursor: pointer;
        color: #00e1ff;
      }
    }
  }
}
</style>
