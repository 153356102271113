<template>
  <teleport to="#teleport-area">
    <div ref="background" class="slider-container">
      <transition-group tag="ul" name="slider">
        <slider-item
          v-for="(item, i) in items"
          :key="item + '-' + i"
          :data="item"
        />
      </transition-group>
    </div>
  </teleport>
</template>

<script>
import SliderItem from "./Item.vue";

export default {
  name: "SliderBody",

  components: {
    SliderItem,
  },

  props: {
    bgClick: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      message: null,
      opened: false,
    };
  },
  computed: {
    items() {
      return this.$store.getters["slider/GET_ITEMS"];
    },
  },

  watch: {
    opened(opened) {
      if (opened) {
        this.$nextTick(() => {
          if (this.$refs.background && this.bgClick === true) {
            this.$refs.background.addEventListener("mousedown", (e) => {
              if (
                this.$store.getters["layout/isTouchDevice"] ||
                e.which === 1
              ) {
                if (e.target === this.$el) {
                  this.close();
                }
              }
            });
          }
        });
      }
    },
  },

  mounted() {
    if (this.data?.duration > 0) {
      setTimeout(this.close, this.data.duration);
    }
  },
};
</script>

<style lang="scss">
.slider-item {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 1600;
  display: flex;
  flex-direction: column;

  &::before {
    content: "";
    @include fsa;
    background-color: $color-shadow;
  }

  & > .container {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: $site-width-h;
    min-height: unit(12);
    max-height: calc(100 * $vh - #{unit(7.8)});
    background-color: $color-white;
    padding: unit(1.6);
    padding-bottom: calc(env(safe-area-inset-bottom) + #{unit(1.6)});
    box-shadow: 0px unit(0.1) unit(0.5) #a9afb3;
    border-radius: unit(1) unit(1) 0px 0px;
    overflow: auto;
    overscroll-behavior-y: contain;
  }

  .slider-header {
    &__title {
      font-weight: 500;
      font-size: unit(1.4);
      line-height: unit(2);
      text-align: center;
      letter-spacing: -0.04em;
    }

    &__subtitle {
      padding: unit(2.4) 0;
      line-height: unit(1.8);
      text-align: center;
      color: #878d91;
    }
  }

  &.transparent {
    &::before {
      background: transparent;
    }
  }
}
</style>
