import NetworkCachedRequest from "./cache";

export default class NetworkCachedRequestManager {
  constructor(network) {
    this._network = network;
    this._requestCahced = {};
    this._requestGroups = {};
    this._lastestRequestId = 0;
  }

  addQueue(request, clearGroup = false) {
    let requestGroup = this._requestCahced[request.group];

    if (!requestGroup) {
      requestGroup = {};
    }

    if (clearGroup === true) {
      Object.keys(requestGroup).forEach((key) => {
        const request = requestGroup[key];

        request.cancel(request);
      });
    }

    requestGroup[request.id] = request;

    this._requestCahced[request.group] = requestGroup;
    this._requestCahced[request.id] = request;
  }

  createRequest(method, path, params, options = {}) {
    this._lastestRequestId += 1;

    const url = `${this._network.axios.defaults.baseURL}/${path}`;

    const request = new NetworkCachedRequest(
      this._network,
      `request.${this._lastestRequestId}`,
      method,
      url,
      params,
      options,
    );

    const { clearGroup } = options;

    this.addQueue(request, clearGroup);

    return request;
  }
}
