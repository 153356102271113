<template>
  <li
    class="lug-modal-item"
    :class="{ small: data.size === 'small', big: data.size === 'big' }"
  >
    <div class="container">
      <div
        v-if="!data.noClose"
        class="lug-modal__close-button"
        @click="removeItem"
      >
        ×
      </div>
      <component
        :is="data.component"
        v-if="data.component"
        :data="data"
        @close="removeItem"
      />
    </div>
  </li>
</template>

<script>
import UserLoginModal from "@/components/resources/auth/UserLoginModal";
import UserTermsAgreeModal from "@/components/resources/auth/UserTermsAgreeModal";
import UserRegisterModal from "@/components/resources/auth/UserRegisterModal";

export default {
  name: "ModalItem",
  components: { UserLoginModal, UserTermsAgreeModal, UserRegisterModal },
  props: ["data"],

  computed: {
    action() {
      return {
        close: this.removeItem,
      };
    },
  },
  mounted() {
    this.$watch("$route", (a, b) => {
      this.removeItem();
    });
    this.$el.addEventListener("mousedown", (e) => {
      if (this.$store.getters["layout/isTouchDevice"] || e.which === 1) {
        if (e.target === this.$el) {
          this.removeItem();
        }
      }
    });
  },

  methods: {
    removeItem() {
      if (typeof this.data.onClose === "function") {
        this.data.onClose(this);
      }

      console.log(this.data);

      this.$store.commit("modal/REMOVE_ITEM", this.data);
    },
  },
};
</script>
