<template>
  <teleport to="#teleport-area">
    <div class="fullscreen-container">
      <transition-group tag="ul" name="fade">
        <FullscreenItem v-for="(item, i) in items" :key="i" :data="item" />
      </transition-group>
    </div>
  </teleport>
</template>

<script>
import FullscreenItem from "./Item";

export default {
  name: "FullscreenBody",
  components: {
    FullscreenItem,
  },
  computed: {
    items() {
      return this.$store.getters["fullscreen/GET_ITEMS"];
    },
  },
};
</script>

<style lang="scss">
.fullscreen-item {
  @include fsf;
  z-index: 1500;

  & > .container {
    @include fill-viewport-min-height;
    @include shadow-box;
    max-width: $max-content-width;
    margin: auto;
    background-color: $color-white;
  }
}
</style>
