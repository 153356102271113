import { RouteRecordRaw } from "vue-router";

// Layouts
import Layout from "@/views/layouts/Layout.vue";
import Blank from "@/views/layouts/Blank.vue";

// Routes
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: Layout,
    redirect: { name: "Home" },
    children: [
      {
        name: "Home",
        component: Blank,
        path: "/",
        redirect: { name: "Spaces" },
        meta: { title: "Home", root: true },
      },
      {
        name: "Spaces",
        component: () => import("@/views/pages/spaces/PageSpaces.vue"),
        path: "spaces",
        meta: { title: "Spaces", root: true },
      },
      {
        name: "SpaceSingle",
        component: () => import("@/views/pages/spaces/PageSpaceSingle.vue"),
        path: "spaces/:id",
        props: true,
        meta: { title: "SpaceSingle", root: true },
      },

      {
        name: "MyPage",
        component: () => import("@/views/pages/my-page/PageMyPage.vue"),
        path: "my-page",
        meta: { title: "MyPage", level: 1 },
      },

      {
        name: "Login",
        component: () => import("@/views/pages/auth/PageUserLogin.vue"),
        path: "login",
        props: true,
        meta: { title: "Login", guest: true },
      },
      {
        name: "Register",
        component: () => import("@/views/pages/auth/PageUserRegister.vue"),
        path: "register",
        props: true,
        meta: { title: "Register", guest: true },
      },
      {
        name: "RegisterTerms",
        component: () => import("@/views/pages/auth/PageUserAgreeTerms.vue"),
        path: "register/terms",
        meta: { title: "RegisterTerms", guest: true },
      },
    ],
  },

  // Error
  {
    path: "/:catchAll(.*)",
    component: Blank,
    children: [
      {
        path: "/:catchAll(.*)",
        component: () => import("@/views/errors/404.vue"),
        name: "404",
        meta: { title: "Error 404" },
      },
    ],
  },
];

export default routes;
