<template>
  <div class="logo">
    <svg-icon name="uhoo" class="logo-image" />
  </div>
</template>

<script>
export default {
  name: "AtomLogo",
};
</script>

<style lang="scss" scoped>
.logo {
  width: unit(10);
  height: unit(3);

  .logo-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
