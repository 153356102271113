<template>
  <div id="wrapper">
    <router-view v-slot="{ Component }" :key="$route.fullPath">
      <transition :name="transitionName">
        <component :is="Component" />
      </transition>
    </router-view>

    <sidebar-layer />
    <alert-layer />
    <modal-layer />
    <slider-layer />
    <dialog-layer />
    <fullscreen-layer />
  </div>
</template>

<script>
import SidebarLayer from "@/components/resources/sidebar/Sidebar.vue";
import AlertLayer from "@/components/molecules/alert/Body.vue";
import ModalLayer from "@/components/molecules/modal/Body.vue";
import DialogLayer from "@/components/molecules/dialog/Body.vue";
import SliderLayer from "@/components/molecules/slider/Body.vue";
import FullscreenLayer from "@/components/molecules/fullscreen/Body.vue";

export default {
  name: "LayoutView",
  setup() {
    return {
      transitionName: "",
    };
  },
  components: {
    SidebarLayer,
    AlertLayer,
    ModalLayer,
    SliderLayer,
    DialogLayer,
    FullscreenLayer,
  },
};
</script>

<style lang="scss" scoped>
#wrapper {
  background: $color-white;
  margin: auto;
}
</style>
