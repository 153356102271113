import axios, { Axios, AxiosResponse, AxiosStatic } from "axios";
import config from "@/config";

import NetworkCachedRequestManager from "./lib/manager";

const ACCESS_TOKEN_KEY = "__UHOO_ACCESS_TOKEN__";
const { adminKey } = config.get("uhoo");
export class Network {
  private static instance;

  private axios;

  public accessToken: any = "";

  private manager: NetworkCachedRequestManager;

  private constructor() {
    this.axios = axios;
    this.axios.defaults.baseURL = config.apiBaseURL;
    this.accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    this.manager = new NetworkCachedRequestManager(this);

    if (this.accessToken) {
      this.setAccessToken(this.accessToken);
    }
  }

  static getInstance(): Network {
    if (!Network.instance) {
      Network.instance = new Network();
    }
    return Network.instance;
  }

  setAccessToken(accessToken: string | null) {
    if (accessToken) {
      this.axios.defaults.headers.common.Authorization = `bearer ${accessToken}`;
      this.accessToken = accessToken;
      localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    }
  }

  unsetAccessToken() {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    delete this.axios.defaults.headers.common.Authorization;
  }

  async post<T>(url: string, params: T, options = {}): Promise<any> {
    const request = this.manager.createRequest("POST", url, params, options);
    const response = await request.run(options);
    const { error, data } = response.data;
    if (error) {
      return Promise.reject(error);
    }

    return data;
  }

  async put<T>(url: string, params?: T, options = {}): Promise<any> {
    const request = this.manager.createRequest("PUT", url, params, options);
    const response = await request.run(options);
    const { error, data } = response.data;
    if (error) {
      return Promise.reject(error);
    }
    return data;
  }

  async patch<T>(url: string, params: T, options = {}): Promise<any> {
    const request = this.manager.createRequest("PATCH", url, params, options);
    const response = await request.run(options);
    const { error, data } = response.data;
    if (error) {
      return Promise.reject(error);
    }
    return data;
  }

  async delete<T>(url: string, params?: T, options = {}): Promise<any> {
    const request = this.manager.createRequest("DELETE", url, params, options);
    const response = await request.run(options);
    const { error, data } = response.data;
    if (error) {
      return Promise.reject(error);
    }
    return data;
  }

  async get<T>(url: string, params?: T, options = {}): Promise<any> {
    const request = this.manager.createRequest("GET", url, params, options);
    const response = await request.run(options);
    const { error, data } = response.data;
    if (error) {
      return Promise.reject(error);
    }
    return data;
  }

  static isCancel(e) {
    return !!axios.isCancel(e);
  }
}

const instance = Network.getInstance();
export default instance;
