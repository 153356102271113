<template>
  <li ref="wrap" class="ui-form-list-item ui-form-list-multiple-item">
    <slot />
  </li>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.ui-form-list-multiple-item {
  @include cl-last;
  & > * {
    float: left;
  }
  & > * {
    width: calc(99% / 2);
    & + * {
      width: calc(99% / 2);
      margin-left: 1%;
    }
  }
}
</style>
