import {
  Module,
  GetterTree,
  MutationTree,
  ActionTree,
  Plugin,
  createStore,
} from "vuex";
import { State } from "./typings";
import network from "@/plugins/network";
import language from "@/plugins/language";
import config from "@/config";

// Models
import spaces from "./models/spaces";
// import $TICKET from './models/ticket';
// import $COUPON from './models/coupon';

// Vuex Modules
import alert from "./modules/alert";
import modal from "./modules/modal";
import slider from "./modules/slider";
import dialog from "./modules/dialog";
import fullscreen from "./modules/fullscreen";
import layout from "./modules/layout";
// layouts
import auth from "./modules/auth";

// ORM.database.register($TICKET, tickets);
// ORM.database.register($COUPON, coupons);
class RootState implements State {
  public readonly config: typeof config = config;

  public readonly language: typeof language = language;

  public readonly network: typeof network = network;

  public timestamp: number = +new Date();
}

const getters: GetterTree<RootState, State> = {
  config: (state) => state.config,
  network: (state) => state.network,
  language: (state) => state.language,
  timestamp: (state) => state.timestamp,
};

const store = createStore({
  modules: {
    alert,
    modal,
    slider,
    dialog,
    fullscreen,
    layout,

    auth,
    spaces,
  },
  state: new RootState(),
  getters,
  mutations: {
    SET_TIMESTAMP(state) {
      setInterval(() => {
        state.timestamp = +new Date();
      }, 1000);
    },
  },
  actions: {},
});

store.commit("SET_TIMESTAMP");

export default store;
