<template>
  <div
    class="ui-textbox"
    :class="{
      hover: state.hover,
      focus: state.focus,
      fill: state.fill,
      error: error,
      disabled: disabled,
      readonly: readonly,
    }"
  >
    <label ref="hover">
      <div class="label">
        {{ label }}
        <span v-if="required" class="required">*</span>
      </div>
      <div class="body">
        <input
          ref="input"
          :type="inputType"
          :placeholder="state.focus ? placeholder : ''"
          :value="modelValue"
          :readonly="readonly"
          :disabled="disabled"
          autocapitalize="off"
          autocomplete="off"
          :maxlength="maxLength"
          :inputmode="inputMode"
          @input="$emit('update:modelValue', $event.target.value)"
          @focus="onFocus"
          @blur="onBlur"
        />

        <div class="unset">
          {{ label }}
        </div>
      </div>
    </label>
    <div v-if="error" class="error">{{ error }}</div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Cleave from "cleave.js";
import "cleave.js/dist/addons/cleave-phone.kr";

export default {
  name: "UiFormattedTextbox",
  props: [
    "modelValue",
    "type",
    "placeholder",
    "label",
    "error",
    "disabled",
    "maxLength",
    "readonly",
    "inputMode",
    "required",
    "formatOptions",
  ],
  emits: ["focus", "blur", "update:modelValue"],
  data() {
    const state = {
      hover: false,
      focus: false,
      fill: false,
      error: false,
    };
    return { state };
  },
  computed: {
    inputType() {
      return this.type ?? "text";
    },
  },
  watch: {
    modelValue: {
      handler(value) {
        this.state.fill = !!value;
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.$store.getters["layout/isTouchDevice"]) {
      this.$refs.hover.addEventListener("mouseover", (e) => {
        this.state.hover = true;
      });
      this.$refs.hover.addEventListener("mouseout", (e) => {
        this.state.hover = false;
      });
    }

    if (this.formatOptions) {
      this.createCleave();
    }
  },
  methods: {
    onFocus(e) {
      if (this.readonly === true) {
        return;
      }
      this.state.focus = true;
      this.$emit("focus", e);
    },
    onBlur(e) {
      if (this.readonly === true) {
        return;
      }
      this.state.focus = false;
      this.$emit("blur", e);
    },
    createCleave() {
      this.cleave = new Cleave(this.$refs.input, this.formatOptions);
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-textbox {
  font-size: unit(1.4);
  line-height: 2;
  color: $color-black !important;
  cursor: text;

  .label {
    position: absolute;
    top: 0;
    left: 0;
    height: unit(5.4);
    padding: unit(1.8) 0 unit(0.6) 0;
    white-space: nowrap;
    transition: all 0.2s;
  }

  .required {
    font-size: inherit;
    color: $color-red;
    margin: 0 unit(0.2);
  }

  input {
    height: unit(5.4);
    padding-top: unit(2);
    transition: all 0.2s;
  }

  .unset {
    display: none;
  }

  & > .error {
    font-size: unit(1.2);
    color: $color-red;
  }
  & {
    .label {
      color: $color-gray-5;
    }
    input {
      border-bottom: solid 1px $color-gray-3;
    }
  }
  // &.hover {
  //   input {
  //     background-color: $color-black;
  //   }
  // }
  &.focus {
    .label {
      color: $color-black;
    }
    input {
      border-bottom: solid 1px $color-black;
    }
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    .label {
      color: $color-gray-5;
    }
    input {
      background-color: $color-gray-1;
      border-bottom: solid 1px $color-gray-3;
    }
  }
  &.readonly {
    opacity: 0.8;
    .label {
      color: $color-gray-5;
    }
    input {
      background-color: $color-gray-0;
      border-bottom: solid 1px $color-gray-3;
    }
  }
  &.focus,
  &.fill {
    .label {
      font-size: unit(1.2);
      line-height: 2;
      padding: 0;
      height: 0;
    }
  }

  &.border {
    .label {
      position: relative;
      height: unset;
      padding: unit(0.4) unit(0.8);
      color: $color-black;
      font-size: unit(1.4);
      font-weight: bold;
      white-space: nowrap;
    }

    input {
      border: solid 1px $color-gray-3;
      border-radius: $radius;
      padding: unit(1.6) unit(0.8) unit(1.6) unit(0.8);
      line-height: 3;

      &::placeholder {
        color: $color-gray-5;
      }
    }

    &.fill,
    &.focus {
      input {
        border: solid 1px $color-black;
      }
    }
  }

  &--primary {
    .label {
      position: relative;
      color: $color-black;
      font-weight: bold;
      font-size: unit(1.6);
      padding: 0;
      height: auto;
    }

    input {
      border: 2px solid #e1e4e6;
      border-radius: unit(5);
      padding: unit(1.2) unit(2);
      font-size: unit(1.4);
      color: $color-black;
      background: $color-white;
      margin-top: unit(1);

      &::placeholder {
        color: #878d91;
      }
    }

    .unset {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: unit(1.2) unit(2);
      font-size: unit(1.4);
      color: #878d91;
      opacity: 1;
      pointer-events: none;
    }

    &.focus,
    &.fill {
      .label {
        line-height: inherit;
        height: auto;
        font-size: unit(1.6);
      }

      input {
        border: 2px solid #e1e4e6;
      }

      .unset {
        opacity: 0;
        // line-height: 0;
        // width: 0;
        // height: 0;
        // visibility: hidden;
      }
    }
  }
}
</style>
