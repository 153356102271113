<template>
  <button
    ref="refBtn"
    type="button"
    class="atom submit-btn"
    :disabled="disabled"
    @click.stop="createRipple"
    @mousedown.stop="createTouchEffect"
    @mousemove.stop="removeTouchEffect"
    @mouseup.stop="removeTouchEffect"
    @touchstart.passive.stop="createTouchEffect"
    @touchmove.passive.stop="removeTouchEffect"
    @touchend.passive.stop="removeTouchEffect"
  >
    <span
      v-if="isRipple"
      v-show="rippleAnimation.animation"
      class="ripple-effect"
      :style="rippleStyle"
    ></span>
    <span
      v-if="isTouchEffect"
      v-show="touchEffectAnimation.animation"
      class="touch-effect"
    ></span>
    <template v-if="loading">
      <ui-lottie
        ref="lottie-loading"
        class="loading"
        :animation-data="loadingJson"
        :width="100"
        :height="48"
        :animating="true"
        :loop="true"
      />
    </template>
    <template v-else>
      <slot></slot>
    </template>
  </button>
</template>

<script>
import {
  ref,
  reactive,
  toRefs,
  computed,
  onMounted,
  getCurrentInstance,
} from "vue";
import loadingJson from "@/assets/animations/loading.json";

export default {
  props: {
    ripple: {
      type: Boolean,
      default: false,
    },
    touchEffect: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const isRipple = ref(props.ripple);
    const isTouchEffect = ref(props.touchEffect);

    const refBtn = ref(null);

    const ripple = reactive({
      animation: false,
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    });

    const touchEffect = reactive({
      animation: false,
    });

    const rippleStyle = computed(() => ({
      top: `${ripple.y}px`,
      left: `${ripple.x}px`,
      width: `${ripple.width}px`,
      height: `${ripple.height}px`,
    }));

    const actions = {
      createRipple(e) {
        if (!refBtn.value || ripple.animation) {
          return;
        }

        const rect = refBtn.value.getBoundingClientRect();
        const diameter = Math.max(rect.width, rect.height) / 2;
        const { clientX } = e;
        const { clientY } = e;
        const offsetTop = rect.top; // + window.pageYOffset
        const offsetLeft = rect.x;

        ripple.x = clientX - offsetLeft;
        ripple.y = clientY - offsetTop;
        ripple.width = diameter;
        ripple.height = diameter;
        ripple.animation = true;

        setTimeout(() => {
          ripple.animation = false;
        }, 300);
      },

      createTouchEffect(e) {
        if (!refBtn.value || touchEffect.animation) {
          return;
        }

        touchEffect.animation = true;
        setTimeout(() => {
          touchEffect.animation = false;
        }, 200);
      },

      removeTouchEffect(e) {
        if (!refBtn.value || touchEffect.animation) {
          return;
        }

        touchEffect.animation = false;
      },
    };

    return {
      isRipple,
      isTouchEffect,
      refBtn,
      rippleAnimation: ripple,
      touchEffectAnimation: touchEffect,
      ...actions,
      rippleStyle,
      loadingJson,
    };
  },
};
</script>

<style lang="scss" scoped>
.atom.submit-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  min-height: unit(4.8);
  padding: unit(1) unit(1.4);
  font-family: inherit;
  font-size: unit(1.6);
  font-weight: 500;
  line-height: 1;
  cursor: pointer;
  background: $color-primary;
  color: $color-white;
  border-radius: $radius;
  @include overflow-hidden;

  .loading {
    @include abs-center;
  }

  .touch-effect {
    @include fsa;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.2);
    animation: touchEffect 0.2s linear;
  }

  @keyframes touchEffect {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .ripple-effect {
    position: absolute;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    transform: translate3d(-50%, -50%, 0);
    animation: rippleEffect 0.3s ease-out;
  }

  &.round {
    border-radius: unit(1);
  }

  &.shadow {
    box-shadow: unit(0) unit(0) unit(1.5) unit(0) rgba(70, 70, 70, 0.1);
  }

  @keyframes rippleEffect {
    from {
      scale: 0;
    }
    to {
      transform: translate3d(-50%, -50%, 0) scale(4);
      opacity: 0;
    }
  }
}
</style>
