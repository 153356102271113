import { Module, ActionContext, GetterTree } from "vuex";
import { State } from "../typings/index";

export class LayerState {
  public items: any = [];
}

const getters: GetterTree<LayerState, State> = {
  GET_ITEMS: (state) => state.items,
};

export const slider: Module<LayerState, State> = {
  namespaced: true,
  state: new LayerState(),
  mutations: {
    ADD_ITEM(state, payload) {
      state.items.push(payload);
    },
    REMOVE_ITEM(state, payload) {
      state.items = state.items.filter(
        (o: any) => o.component !== payload.component,
      );
    },
  },
  getters,
};

export default slider;
