<template>
  <li ref="refItem" class="fullscreen-item">
    <div class="container">
      <CloseHeader v-if="!data?.noClose" @close="removeItem" />
      <component :is="data?.component" :data="data" @close="removeItem" />
    </div>
  </li>
</template>

<script>
// import SearchCondition from '@/components/resources/spaces/SearchCondition.vue';
// import SearchKeyword from '@/components/resources/spaces/SearchKeyword.vue';
// import LanguageSelect from '@/components/resources/language/LanguageSelect.vue';
// import ReserveCondition from '@/components/resources/reserve/ReserveCondition.vue';
// import TicketCondition from '@/components/resources/tickets/TicketCondition.vue';

import {
  computed,
  defineAsyncComponent,
  getCurrentInstance,
  onMounted,
  ref,
  shallowRef,
  toRefs,
  watch,
} from "@vue/runtime-core";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import CloseHeader from "@/components/organisms/CloseHeader.vue";

export default {
  name: "FullscreenItem",
  components: {
    CloseHeader,

    // SearchCondition,
    // SearchKeyword,
    // LanguageSelect,
    // ReserveCondition,
    // TicketCondition,
  },
  props: ["data"],

  setup(props, { emit }) {
    const { data } = toRefs(props);
    const route = useRoute();
    const refItem = ref(null);
    const store = useStore();

    const removeItem = () => {
      store.commit("fullscreen/REMOVE_ITEM", data.value);
    };

    onMounted(() => {
      watch(
        () => route,
        () => {
          removeItem();
        },
      );

      refItem.value.addEventListener("mousedown", (e) => {
        if (store.getters["layout/isTouchDevice"] || e.which === 1) {
          if (e.target === refItem.value) {
            removeItem();
          }
        }
      });
    });

    return {
      refItem,
      removeItem,
      close: removeItem,
    };
  },
};
</script>
