import { App } from "vue";
import config from "@/config";

import { MapType } from "./typings";
import NaverMap from "./lib/naver-map";
// import GoogleMap from "./lib/google-map"

const mapConfig = config.get("map");

const Maps = {
  // google: GoogleMap,
  // mapBox: MapBoxMap
  NAVER: NaverMap,
} as const;

class MobidicMap {
  private static instance;

  public mapType: MapType = MapType.NAVER;

  private mapInstance: any = {};

  private timeout?: number | null;

  get map() {
    return this.mapInstance?.map;
  }

  get initiated() {
    return this.mapInstance?.initiated || false;
  }

  get loaded() {
    return this.mapInstance?.loaded || false;
  }

  static defaultInstance() {
    if (!MobidicMap.instance) {
      MobidicMap.instance = new MobidicMap();
    }

    return MobidicMap.instance;
  }

  useMap(type: MapType) {
    if (type in MapType) {
      this.mapType = type;
    } else {
      this.mapType = MapType.NAVER;
    }
  }

  async initiate(callback) {
    this.mapInstance = new Maps[this.mapType](mapConfig[MapType[this.mapType]]);
    await this.mapInstance.load(callback);
  }

  initMap(el: HTMLElement, options = {}) {
    const map = this.mapInstance.initMap(el, options);
    return map;
  }

  clearMap() {
    this.mapInstance.destroy();
    this.mapInstance = {};
    this.mapType = MapType.NAVER;
  }

  addMapEventListener(eventName: string, callback: Function) {
    this.mapInstance.addMapEventListener(eventName, callback);
  }

  removeMapEventListener(eventName: string) {
    this.mapInstance.removeMapEventListener(eventName);
  }

  triggerEvent(target, eventName, eventObject) {
    this.mapInstance.triggerEvent(target, eventName, eventObject);
  }

  createMarker(
    {
      id,
      latitude,
      longitude,
      zIndex,
      ...args
    }: {
      id: number;
      latitude: string;
      longitude: string;
      zIndex?: number;
    },
    callback?: Function,
  ) {
    this.mapInstance.createMarker(
      {
        id,
        latitude,
        longitude,
        zIndex,
        ...args,
      },
      callback,
    );
  }

  addMarkerEventListener(marker: any, eventName: string, callback: Function) {
    this.mapInstance.addMarkerEventListener(marker, eventName, callback);
  }

  removeMarkerEventListener(marker: any, eventName: string) {
    this.mapInstance.removeMarkerEventListener(marker, eventName);
  }

  removeMarker({ id }) {
    this.mapInstance.removeMarker({ id });
  }

  clearMarkers() {
    this.mapInstance.clearMarkers();
  }

  updateMarkers() {
    const data = this.mapInstance.updateMarkers();
    return data;
  }

  isInBounds(key) {
    return this.mapInstance.isInBounds(key);
  }

  getZoom() {
    return this.mapInstance.getZoom();
  }

  setZoom(value) {
    this.mapInstance.setZoom(value);
  }

  getCenter() {
    const center = this.mapInstance?.getCenter();
    return center;
  }

  setCenter({ latitude, longitude, delay = 0 }) {
    // console.trace();
    console.log("setCenter", { latitude, longitude, delay });

    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    this.timeout = setTimeout(() => {
      this.mapInstance.setCenter(latitude, longitude);
    }, delay);
  }

  panTo({ latitude, longitude, delay = 0 }) {
    // console.trace();
    console.log("panTo", { latitude, longitude, delay });

    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    this.timeout = setTimeout(() => {
      this.mapInstance?.panTo({ latitude, longitude });
    }, delay);
  }

  getRadius() {
    const radius = this.mapInstance.getRadius();
    return radius;
  }

  setSize(width, height) {
    this.mapInstance.setSize(width, height);
  }

  resize() {
    this.mapInstance.resize();
  }

  getDistanceBetween(pointA, pointB) {
    const distance = this.mapInstance.getDistanceBetween(pointA, pointB);

    return distance;
  }
}

export default MobidicMap;

export const plugin = {
  install: (app: App, options: any = {}) => {
    const mobidicMap = MobidicMap.defaultInstance();

    app.config.globalProperties.$mobidicMap = mobidicMap;
    app.provide("$mobidicMap", mobidicMap);
  },
};
