<template>
  <div
    class="ui-textarea"
    :class="{
      readonly: readonly,
      disabled: disabled,
      focus: focus,
      hover: hover,
      fill: fill,
      error: error,
    }"
  >
    <div class="ui-textarea__label">
      <label :for="elId">{{ label }}</label>
    </div>

    <div class="ui-textarea__body">
      <textarea
        :id="elId"
        v-model="model"
        :placeholder="placeholder"
        :readonly="readonly"
        :disabled="disabled"
        @focus="onFocus"
        @blur="onBlur"
        @mouseover="onMouseOver"
        @mouseout="onMouseOut"
      ></textarea>
    </div>
  </div>
</template>

<script>
import { computed, ref, toRefs } from "@vue/reactivity";
import { onMounted } from "vue";
import { isTouchDevice } from "@/helpers";

export default {
  name: "UiTextarea",
  props: {
    id: String,
    modelValue: String,
    label: String,
    placeholder: String,
    readonly: Boolean,
    disabled: Boolean,
  },
  emits: ["update:modelValue", "focus", "blur"],

  setup(props, { emit }) {
    const { modelValue, id, readonly } = toRefs(props);

    const elId = computed(() => id.value);

    const state = {
      focus: ref(false),
      hover: ref(false),
      fill: ref(false),
      error: ref(false),
    };

    const listeners = {
      onFocus(e) {
        if (readonly.value === true) {
          return;
        }
        state.focus.value = true;
        emit("focus", e);
      },
      onBlur(e) {
        if (readonly === true) {
          return;
        }
        state.focus.value = false;
        emit("blur", e);
      },
      onMouseOver(e) {
        state.hover.value = true;
      },
      onMouseOut(e) {
        state.hover.value = false;
      },
    };

    const model = computed({
      get() {
        const { value } = modelValue;
        state.fill.value = !!value;
        return modelValue.value;
      },
      set(value) {
        emit("update:modelValue", value);
      },
    });

    return {
      model,
      elId,
      ...listeners,
      ...state,
    };
  },
};
</script>

<style lang="scss" scoped>
.ui-textarea {
  &__label {
    font-weight: 700;
    font-size: unit(1.6);
  }

  &__body {
    margin-top: unit(1);
    background: $color-white;
    border: 2px solid #e1e4e6;
    border-radius: unit(2.2);

    textarea {
      border-radius: inherit;
      min-height: unit(16);
      padding: unit(1.2) unit(2);

      &::placeholder {
        font-size: unit(1.4);
        color: #878d91;
      }
    }

    .unset {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: unit(1.2) unit(2);
      font-size: unit(1.4);
      color: #878d91;
      opacity: 1;
      pointer-events: none;
    }
  }

  &.focus,
  &.fill {
    .label {
      line-height: inherit;
      height: auto;
      font-size: unit(1.6);
    }

    .unset {
      opacity: 0;
    }
  }
}
</style>
