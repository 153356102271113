<template>
  <transition
    :enter-active-class="transition.enter"
    :leave-active-class="transition.leave"
  >
    <div
      v-show="isActive"
      :class="['c-toast', `c-toast--${type}`, `c-toast--${position}`]"
      role="alert"
      @mouseover="toggleTimer(true)"
      @mouseleave="toggleTimer(false)"
      @click="click"
    >
      <div v-html="message"></div>
    </div>
  </transition>
</template>

<script>
import { removeElement } from "./helpers/remove-element";
import Timer from "./helpers/timer";
import Positions, { definePosition } from "./defaults/positions";
import eventBus from "./helpers/event-bus";

export default {
  name: "ToasterComponent",
  props: {
    message: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "default",
    },
    position: {
      type: String,
      default: Positions.BOTTOM_RIGHT,
      validator(value) {
        return Object.values(Positions).includes(value);
      },
    },
    maxToasts: {
      type: [Number, Boolean],
      default: false,
    },
    duration: {
      type: [Number, Boolean],
      default: 4000,
    },
    dismissible: {
      type: Boolean,
      default: true,
    },
    queue: {
      type: Boolean,
      default: false,
    },
    pauseOnHover: {
      type: Boolean,
      default: true,
    },
    useDefaultCss: {
      type: Boolean,
      default: true,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    onClick: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isActive: false,
      parentTop: null,
      parentBottom: null,
      isHovered: false,
      timer: null,
    };
  },
  computed: {
    correctParent() {
      return definePosition(
        this.$props.position,
        this.$data.parentTop,
        this.$data.parentBottom,
      );
    },
    transition() {
      return definePosition(
        this.$props.position,
        {
          enter: "fadeInDown",
          leave: "fadeOut",
        },
        {
          enter: "fadeInUp",
          leave: "fadeOut",
        },
      );
    },
  },
  beforeMount() {
    this.createParents();
    this.setDefaultCss();
    this.setupContainer();
  },
  mounted() {
    this.showNotice();
    eventBus.$on("toast-clear", this.close);
  },
  beforeUnmount() {
    eventBus.$off("toast-clear", this.close);
  },
  methods: {
    createParents() {
      this.$data.parentTop = document.querySelector(".c-toast-container--top");
      this.$data.parentBottom = document.querySelector(
        ".c-toast-container--bottom",
      );

      if (this.$data.parentTop && this.$data.parentBottom) {
        return;
      }

      if (!this.$data.parentTop) {
        this.$data.parentTop = document.createElement("div");
        this.$data.parentTop.className =
          "c-toast-container c-toast-container--top";
      }

      if (!this.$data.parentBottom) {
        this.$data.parentBottom = document.createElement("div");
        this.$data.parentBottom.className =
          "c-toast-container c-toast-container--bottom";
      }
    },
    setDefaultCss() {
      const type = this.$props.useDefaultCss ? "add" : "remove";
      this.$data.parentTop.classList[type]("v--default-css");
      this.$data.parentBottom.classList[type]("v--default-css");
    },
    setupContainer() {
      const container = document.body;
      container.appendChild(this.$data.parentTop);
      container.appendChild(this.$data.parentBottom);
    },
    shouldQueue() {
      if (!this.$props.queue && this.$props.maxToasts === false) {
        return false;
      }

      if (this.$props.maxToasts !== false) {
        return (
          this.$props.maxToasts <=
          this.$data.parentTop.childElementCount +
            this.$data.parentBottom.childElementCount
        );
      }

      return (
        this.$data.parentTop.childElementCount > 0 ||
        this.$data.parentBottom.childElementCount > 0
      );
    },
    showNotice() {
      if (this.shouldQueue()) {
        this.queueTimer = setTimeout(this.showNotice, 250);
        return;
      }

      this.correctParent.insertAdjacentElement("afterbegin", this.$el);
      this.$data.isActive = true;

      this.$data.timer =
        this.$props.duration !== false
          ? new Timer(this.close, this.$props.duration)
          : null;
    },
    click(...args) {
      this.$props.onClick.apply(null, ...args);

      if (this.$props.dismissible) {
        this.close();
      }
    },
    toggleTimer(newVal) {
      if (this.$data.timer && this.$props.pauseOnHover) {
        if (newVal) {
          this.$data.timer.pause();
        } else {
          this.$data.timer.resume();
        }
      }
    },
    stopTimer() {
      if (this.$data.timer) {
        this.$data.timer.stop();
      }
      clearTimeout(this.queueTimer);
    },
    close(...args) {
      this.stopTimer();
      this.isActive = false;
      setTimeout(() => {
        this.$props.onClose.apply(null, ...args);
        removeElement(this.$el);
      }, 150);
    },
  },
};
</script>
<style lang="scss">
@import "./themes/default/index.scss";

.v--default-css {
  @import "./themes/default/colors.scss";
  @import "./themes/default/toast.scss";
}
</style>
