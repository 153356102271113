import { App, Component } from "vue";
import UiLogo from "./atoms/Logo.vue";
import UiButton from "./atoms/Btn.vue";
import UiSubmitButton from "./atoms/SubmitBtn.vue";
import UiFormList from "./atoms/FormList.vue";
import UiFormListMultipleItem from "./atoms/FormListMultipleItem.vue";
import UiIconInput from "./atoms/IconInput.vue";
import UiTextarea from "./atoms/Textarea.vue";

import UiForm from "./molecules/Form.vue";
import UiFormListItem from "./atoms/FormListItem.vue";
import UiTextbox from "./molecules/Textbox.vue";
import UiFormattedTextbox from "./molecules/FormattedTextbox.vue";
import UiAuthTextbox from "./molecules/AuthenticateTextbox.vue";
import UiSelect from "./molecules/Select.vue";
import UiPhoneCombobox from "./molecules/PhoneCombobox.vue";
import UiCheckbox from "./molecules/Checkbox.vue";
// import UiFlicker from './molecules/Flickering.vue';

const plugin = {
  install: (app: App) => {
    app.component("UiLogo", UiLogo);
    app.component("UiForm", UiForm);
    app.component("UiFormList", UiFormList);
    app.component("UiFormListItem", UiFormListItem);
    app.component("UiFormListMultipleItem", UiFormListMultipleItem);
    app.component("UiIconInput", UiIconInput);

    app.component("UiButton", UiButton);
    app.component("UiSubmitButton", UiSubmitButton);
    app.component("UiTextbox", UiTextbox);
    app.component("UiTextarea", UiTextarea);
    app.component("UiFormattedTextbox", UiFormattedTextbox);
    app.component("UiAuthTextbox", UiAuthTextbox);
    app.component("UiSelect", UiSelect);
    app.component("UiPhoneCombobox", UiPhoneCombobox);
    app.component("UiCheckbox", UiCheckbox);
    // app.component('UiFlicker', UiFlicker);
  },
};

export default plugin;
