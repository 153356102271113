import crypto from "crypto-js";

const NETWORK_KEY = "__UHOO_NETWORK_KEY__";

function hash(str) {
  crypto.AES.encrypt(str, NETWORK_KEY).toString();
}

export default class NetworkCachedRequest {
  get id() {
    return this._id;
  }

  get group() {
    return this._group;
  }

  constructor(network, id, method, uri, params = {}) {
    this._network = network;
    this._id = id;
    this._uri = uri;
    this._method = method.toUpperCase();
    this._params = params;
    this._url = new URL(uri);
    this._group = hash(
      `${this._url.protocol}://${this._url.hostname}/${this._url.pathname}`,
    );
    this._cancelSource = null;
  }

  _run(options = {}) {
    if (this._method === "POST") {
      return this._network.axios.post(this._uri, this._params, {
        cancelToken: this._cancelSource.token,
        requestId: this._id,
        ...options,
      });
    }
    if (this._method === "PUT") {
      return this._network.axios.put(this._uri, this._params, {
        cancelToken: this._cancelSource.token,
        requestId: this._id,
        ...options,
      });
    }
    if (this._method === "GET") {
      return this._network.axios.get(this._uri, {
        params: this._params,
        cancelToken: this._cancelSource.token,
        requestId: this._id,
        ...options,
      });
    }
    if (this._method === "DELETE") {
      return this._network.axios.delete(this._uri, {
        params: this._params,
        cancelToken: this._cancelSource.token,
        requestId: this._id,
        ...options,
      });
    }
    return Promise.reject(new Error("UNKNOWN_METHOD"));
  }

  run(options = {}) {
    return new Promise((resolve, reject) => {
      const { CancelToken } = this._network.axios;
      this._cancelSource = CancelToken.source();

      this._run(options).then(resolve).catch(reject);
    });
  }

  async cancel({ message }) {
    if (this._cancelSource) {
      await this._cancelSource.cancel(message);
    }
  }
}
