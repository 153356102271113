<template>
  <div id="root">
    <template v-if="loaded">
      <router-view />
    </template>
  </div>
</template>

<script>
import { onBeforeMount, ref } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { isTouchDevice } from "@/helpers";
import { verifyAuth } from "./composable/auth";

// import { authVerify } from '@/composable/auth';
// import { wnGetSafeArea } from '@/composable/wn.interface';

export default {
  setup() {
    const $router = useRouter();
    const $store = useStore();

    const loaded = ref(false);
    onBeforeMount(() => {
      verifyAuth().finally(() => {
        setTimeout(() => {
          loaded.value = true;
        }, 0);
      });

      if (!isTouchDevice) {
        $router.replace({ name: "Landing" });
      }

      // wnGetSafeArea({
      //   callback: (data) => {
      //     console.log(data);
      //   },
      // });

      // await authVerify();
    });

    return {
      loaded,
    };
  },

  components: {
    // ModalLayer,
    // AlertLayer,
    // SliderLayer,
  },
};
</script>
